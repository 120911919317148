import Vue from 'vue'
import VCalendar from 'v-calendar'
import './style.scss'

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  locale: 'en-US',
  locales: {
    'en-US': {
      firstDayOfWeek: 2,
      masks: {
        input: ['MMM DD, YYYY'],
      },
    },
  },
})

import Vue from 'vue'
import VueTippy, { TippyComponent } from 'vue-tippy'
import './style.scss'

Vue.use(VueTippy, {
  arrow: true,
  flipOnUpdate: true,
  zIndex: 99,
})

Vue.component('Tippy', TippyComponent)

import Vue from 'vue'
import './style.scss'
import {
  Vuetable,
  VuetablePagination,
  VuetablePaginationInfo,
  VuetablePaginationInfoMixin,
  VuetableFieldCheckbox,
  VuetableRowHeader,
} from 'vuetable-2'

Vue.component('Vuetable', Vuetable)
Vue.component('VuetablePagination', VuetablePagination)
Vue.component('VuetablePaginationInfo', VuetablePaginationInfo)
Vue.component('VuetablePaginationInfoMixin', VuetablePaginationInfoMixin)
Vue.component('VuetableFieldCheckbox', VuetableFieldCheckbox)
Vue.component('VuetableRowHeader', VuetableRowHeader)

const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['project/isAdmin'] = require('../middleware/project/isAdmin.js')
middleware['project/isAdmin'] = middleware['project/isAdmin'].default || middleware['project/isAdmin']

middleware['project/isPm'] = require('../middleware/project/isPm.js')
middleware['project/isPm'] = middleware['project/isPm'].default || middleware['project/isPm']

middleware['removeDoubleSlash'] = require('../middleware/removeDoubleSlash.js')
middleware['removeDoubleSlash'] = middleware['removeDoubleSlash'].default || middleware['removeDoubleSlash']

middleware['timeOff/isAdmin'] = require('../middleware/timeOff/isAdmin.js')
middleware['timeOff/isAdmin'] = middleware['timeOff/isAdmin'].default || middleware['timeOff/isAdmin']

middleware['timeOff/isManager'] = require('../middleware/timeOff/isManager.js')
middleware['timeOff/isManager'] = middleware['timeOff/isManager'].default || middleware['timeOff/isManager']

export default middleware

//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'AccessWrapper',
  props: {
    accessKey: {
      type: Number,
      default: null,
      required: true,
    },
    redirectUrl: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      getAccessKeys: 'global/auth/getAccessKeys',
    }),
  },
  mounted() {
    this.hasAccess()
    this.redirect()
  },
  methods: {
    redirect() {
      if (this.redirectUrl && !this.hasAccess()) {
        return this.$router.push({
          path: this.redirectUrl,
        })
      } else {
        return false
      }
    },
    hasAccess() {
      return this.getAccessKeys.includes(this.accessKey)
    },
  },
}

/**
 * @vuex-state {object} filter - Object with applied filters for table
 */

export const state = () => ({
  filter: {
    Archived: null,
  },
})

export const mutations = {
  setArchivedFilter: (state, payload) => {
    state.filter.Archived = payload
  },
}
export const getters = {
  getArchivedFilter: (state) => {
    return state.filter.Archived
  },
}

export const actions = {
  updateArchivedFilter: ({ commit }, value) => {
    commit('setArchivedFilter', value)
  },
}

export const state = () => ({
  appliedFilters: 0,
  appliedFiltersKeys: [],
  amountFilteredItems: 0,
  startResultCount: 0,
  filtersReset: false,
  searchQuery: '',
  filter: {
    StartDate: null,
  },
})

export const actions = {
  setStartDateFilter: ({ commit, state }, payload) => {
    if (state.filter.StartDate === null && payload !== null) {
      commit('global/filters/setAppliedFilters', 1, { root: true })
    } else if (payload === null) {
      commit('global/filters/setAppliedFilters', -1, { root: true })
    }
    state.filter.StartDate = payload
    commit('global/filters/setFiltersReset', false, { root: true })
  },
  updateSearchQuery: ({ commit }, value) => {
    commit('setSearchQuery', value)
  },
  clearSearchQuery: ({ commit }) => {
    commit('setSearchQuery', '')
  },
}

export const mutations = {
  setAmountFilteredItems: (state, payload) => {
    state.amountFilteredItems = payload
  },
  setAppliedFilters: (state, payload) => {
    payload !== 0
      ? (state.appliedFilters += payload)
      : (state.appliedFilters = 0)
  },
  addAppliedFiltersKeys: (state, payload) => {
    state.appliedFiltersKeys.push(payload)
  },
  deleteAppliedFiltersKeys: (state, payload) => {
    const arr = [...state.appliedFiltersKeys]
    const index = arr.indexOf(payload)
    if (index > -1) {
      arr.splice(index, 1)
    }
    state.appliedFiltersKeys = arr
  },
  clearAppliedFiltersKeys: (state) => {
    state.appliedFiltersKeys = []
  },
  setStartResultCount(state, payload) {
    state.startResultCount = payload
  },
  setFiltersReset(state, payload) {
    state.filtersReset = payload
  },
  deleteStartDateFilter: (state) => {
    state.filter.StartDate = null
  },
  setSearchQuery: (state, payload) => {
    state.searchQuery = payload
  },
}

export const getters = {
  getAmountFilteredItems: (state) => {
    return state.amountFilteredItems
  },
  getAppliedFilters: (state) => {
    return state.appliedFilters
  },
  getAppliedFiltersKeys: (state) => {
    return state.appliedFiltersKeys
  },
  getStartResultCount: (state) => {
    return state.startResultCount
  },
  getFiltersReset: (state) => {
    return state.filtersReset
  },
  getStartDateFilter: (state) => {
    return state.filter.StartDate
  },
  getSearchQuery: (state) => {
    return state.searchQuery
  },
}

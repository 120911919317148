/**
 * @vuex-state {object} BusinessUnitMembership - list of users which connected to your BU
 */

import { sortArrOfObjByAlphabetical } from '~/components/global/Filters/functions.js'

export const state = () => ({
  businessUnitMembership: {
    users: [],
  },
  allBusinessUnitMembership: [],
})

export const actions = {
  async fetchBusinessUnitMembership({ commit }) {
    const result = await this.$axios({
      method: 'GET',
      url: '/api/Users/BusinessUnitUsers',
    })
    const allMembers = result.data
    const members = allMembers.reduce((obj, val) => {
      !Array.isArray(obj.managers) && (obj.managers = [])
      if (
        val.manager !== null &&
        !obj.managers.find((manager) => manager.id === val.manager.id)
      ) {
        obj.managers = [...obj.managers, { ...val.manager }]
      }
      !Array.isArray(obj.users) && (obj.users = [])
      if (!obj.users.find((user) => user.id === val.user.id)) {
        obj.users = [...obj.users, { ...val.user }]
      }
      return obj
    }, {})
    sortArrOfObjByAlphabetical(members.managers, 'fullName')
    sortArrOfObjByAlphabetical(members.users, 'fullName')
    commit('setAllBusinessUnitMembership', allMembers)
    commit('setBusinessUnitMembership', members)
  },
}
export const mutations = {
  setBusinessUnitMembership: (state, payload) => {
    state.businessUnitMembership = payload
  },
  setAllBusinessUnitMembership: (state, payload) => {
    state.allBusinessUnitMembership = payload
  },
}
export const getters = {
  getBusinessUnitMembership(state) {
    return state.businessUnitMembership
  },
  getAllBusinessUnitMembership(state) {
    return state.allBusinessUnitMembership
  },
}

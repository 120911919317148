/**
 * @vuex-state {object} userBalance - Object with leave balances of user
 */

export const state = () => ({
  leaveTypes: [],
  requestInfo: {},
  requestDetails: {},
  highlightedDays: {},
})

export const actions = {
  async fetchLeaveTypes({ commit }) {
    const result = await this.$axios({
      method: 'GET',
      url: '/api/TimeOff/Types',
    })
    commit('setleaveTypes', result.data)
  },
  async fetchTimeOffDetails({ commit }, id) {
    const result = await this.$axios({
      method: 'GET',
      url: `/api/TimeOff/${id}`,
    })
    commit('setTimeOffDetails', result)
    return result
  },
  async submitTimeOffRequests(
    { commit },
    {
      requestId,
      type,
      comments,
      startDate,
      endDate,
      days,
      backupApprovers,
      isEdit,
      user,
    }
  ) {
    const id = requestId || 0
    const method = isEdit ? 'PUT' : 'POST'
    const data = {
      id,
      type,
      comments,
      startDate,
      endDate,
      days,
      backupApprovers,
      user,
    }
    const result = await this.$axios({
      method,
      url: '/api/TimeOff',
      data,
    })
    commit('setRequestInfo', result)
    return result
  },
  async deleteTimeOffRequest({ commit }, id) {
    await this.$axios({
      method: 'DELETE',
      url: `/api/TimeOff?id=${id}`,
    }).then(() => {
      this.$router.go()
    })
  },
  async rejectTimeOffRequest({ commit }, { id, comments }) {
    const data = {
      id,
      reason: comments,
    }

    const result = await this.$axios({
      method: 'POST',
      url: `/api/TimeOff/Reject/`,
      data,
    })
    commit('setRequestInfo', result)
  },
  async approveTimeOffRequest({ commit }, id) {
    const data = {
      id,
    }

    const result = await this.$axios({
      method: 'POST',
      url: `/api/TimeOff/Approve/`,
      data,
    })
    commit('setRequestInfo', result)
    return result
  },
  async fetchHighlightedDays({ commit }, { startDate, endDate, consultantId }) {
    const result = await this.$axios({
      method: 'GET',
      url: '/api/Days',
      params: { userId: consultantId, startDate, endDate },
    })
    commit('setHighlightedDays', result.data)
  },
}
export const mutations = {
  setleaveTypes: (state, payload) => {
    state.leaveTypes = payload
  },
  setRequestInfo: (state, payload) => {
    state.requestInfo = payload
  },
  setTimeOffDetails: (state, payload) => {
    state.requestDetails = payload.data
  },
  setHighlightedDays: (state, payload) => {
    state.highlightedDays = payload
  },
}
export const getters = {
  getLeaveTypes(state) {
    return state.leaveTypes
  },
  getRequestInfo(state) {
    return state.requestInfo
  },
  getTimeOffDetails: (state, payload) => {
    return state.requestDetails
  },
  getHighlightedDays: (state, payload) => {
    return state.highlightedDays
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @vue-prop {String} text - Text for the button
 * @vue-prop {String} [route=false] - Route for nuxt-link tag
 * @vue-prop {String} type - Type of the button
 * @vue-prop {String} [href=false] - Href attr if button is link
 * @vue-prop {String} [variant='primary'] - Style variant for the button
 * @vue-prop {Boolean} [disabled=false] - Attr for apply disabled styles
 * @vue-data {String} defaultClasses - Tailwind classes for default button style
 * @vue-data {Object} variants - Tailwind classes for different button style
 * @vue-computed {String} variantClasses - Returns list of classes for the selected variant
 * @vue-event {String} click - Emmit event when button was clicked
 */

/**
 * The only true button.
 * @displayName Best Button
 */

export default {
  name: 'Button',
  props: {
    /**
     * Text for the button
     */
    text: {
      type: String,
      default: null,
    },
    /**
     * [route=false] - Route for nuxt-link tag
     */
    route: {
      type: String,
      default: null,
    },
    /**
     * Type of the button
     */
    type: {
      type: String,
      default: 'button',
    },
    /**
     * [href=false] - Href attr if button is link
     */
    href: {
      type: String,
      default: null,
    },
    /**
     * [variant='primary'] - Style variant for the button
     */
    variant: {
      type: String,
      default: null,
    },
    /**
     * [disabled=false] - Attr for apply disabled styles
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Target attribute if the button is link
     */
    target: {
      type: String,
      default: '_self',
    },
  },
  data() {
    return {
      defaultClasses:
        'text-sm inline-flex items-center py-2 transition duration-100 ease-in-out focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:cursor-not-allowed disabled:border-gray-38 appearance-none',
      variants: {
        primary:
          'text-white bg-blue-100 border border-blue-100 rounded-sm hover:bg-blue-54 hover:border-blue-54 px-4 disabled:bg-gray-38',
        secondary:
          'text-blue-100 bg-white border border-blue-100 rounded-sm hover:border-blue-54 hover:text-blue-54 px-4',
        error: 'text-white bg-red-100 rounded-sm hover:bg-red-54 px-4',
        success: 'text-white bg-green-100 rounded-sm hover:bg-green-54 px-4',
        link: 'text-black underline hover:text-black px-1',
      },
    }
  },
  computed: {
    variantClasses() {
      if (this.variant === null) {
        return this.variants.primary
      } else if (this.variant === 'secondary') {
        return this.variants.secondary + ' btn-secondary'
      } else if (this.variant === 'error') {
        return this.variants.error
      } else if (this.variant === 'success') {
        return this.variants.success
      } else {
        return this.variants.link
      }
    },
  },
}

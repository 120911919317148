//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CrossIcon from '~/assets/icons/cross.svg'

export default {
  name: 'DeletedRequest',
  components: {
    CrossIcon,
  },
  data() {
    return {}
  },
  methods: {
    close() {
      this.$modal.hide('deleted-request')
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @vue-prop {text} header - Title for a page
 * @vue-prop {Boolean} isUpcoming - Show or hide upcoming time off requests
 */

import { mapGetters } from 'vuex'
import InfoIcon from '~/assets/icons/info.svg'
import Loading from '~/components/global/Loading/Loading'

export default {
  name: 'Balance',
  components: {
    Loading,
    InfoIcon,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    isUpcoming: {
      type: Boolean,
      default: false,
    },
    isEditPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
    }
  },

  computed: {
    ...mapGetters({
      getUsers: 'timeoff/users/getUsers',
      getAccountMembership: 'global/accountMembership/getAccountMembership',
    }),
    endHours() {
      if (this.getUsers.timeoffBalance === 1) {
        return ''
      } else {
        return 's'
      }
    },
    endUpcoming() {
      if (this.getUsers.upcomingTimeoffCount === 1) {
        return ''
      } else {
        return 's'
      }
    },
  },
  watch: {
    getUsers(val) {
      if (val.timeoffBalance >= 0) {
        this.isLoading = false
      }
    },
  },
}

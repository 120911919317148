/**
 * @vuex-state {object} filter - Object with applied filters for table
 */

export const state = () => ({
  fieldName: 'startDate',
})

export const mutations = {
  setFieldName: (state, payload) => {
    state.fieldName = payload
  },
}
export const getters = {
  getFieldName: (state) => {
    return state.fieldName
  },
}

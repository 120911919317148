/**
 * @vuex-state {object} userBalance - Object with leave balances of user
 */

export const state = () => ({
  users: {
    managees: [],
  },
  otherConsultant: {
    requestForSomeone: false,
    selected: null,
  },
})

export const actions = {
  async fetchUsers({ commit }) {
    const result = await this.$axios({
      method: 'GET',
      url: '/api/users',
    })
    const user = result.data
    const countResult = await this.$axios({
      method: 'GET',
      url: '/api/TimeOff/UpcomingCount',
    })
    user.upcomingTimeoffCount = countResult.data
    commit('setUsers', user)
  },
}
export const mutations = {
  setUsers: (state, payload) => {
    state.users = payload
  },
  /**
   * @param {any} payload - if payload boolean then otherConsultant.requestForSomeone will be changed, else - otherConsultant.selected will be changed
   */
  setOtherConsultant: (state, payload) => {
    if (typeof payload === 'boolean') {
      state.otherConsultant.requestForSomeone = payload
    } else {
      state.otherConsultant.selected = payload
    }
  },
}
export const getters = {
  getUsers(state) {
    return state.users
  },
  getOtherConsultant(state) {
    return state.otherConsultant
  },
}

export const state = () => ({
  isTableLoaded: 0,
})

export const mutations = {
  setIsTableLoaded(state) {
    state.isTableLoaded = state.isTableLoaded + 1
  },
}

export const getters = {
  getIsTableLoaded(state) {
    return state.isTableLoaded
  },
}

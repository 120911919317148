import Vue from 'vue'
import Toast, { TYPE } from 'vue-toastification'
import checkNotificationIcon from '~/assets/icons/checked-notification-icon.svg'
import 'vue-toastification/dist/index.css'
import './style.scss'

Vue.use(Toast, {
  // ToastOptions object for all type of toasts
  maxToasts: 20,
  newestOnTop: true,
  closeOnClick: false,
  hideProgressBar: true,
  timeout: 30000,
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.SUCCESS]: {
      icon: checkNotificationIcon,
    },
  },
})

export const GlobalField = () => import('../../components/global/GlobalField.vue' /* webpackChunkName: "components/global-field" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/global/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const InputRadioCheckbox = () => import('../../components/global/InputRadioCheckbox.vue' /* webpackChunkName: "components/input-radio-checkbox" */).then(c => wrapFunctional(c.default || c))
export const InputText = () => import('../../components/global/InputText.vue' /* webpackChunkName: "components/input-text" */).then(c => wrapFunctional(c.default || c))
export const InputTextarea = () => import('../../components/global/InputTextarea.vue' /* webpackChunkName: "components/input-textarea" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/global/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const SearchPanel = () => import('../../components/global/SearchPanel.vue' /* webpackChunkName: "components/search-panel" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/global/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const StatusDot = () => import('../../components/global/StatusDot.vue' /* webpackChunkName: "components/status-dot" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/global/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const AccessWrapper = () => import('../../components/global/AccessWrapper/index.vue' /* webpackChunkName: "components/access-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/global/Button/index.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/global/Dropdown/index.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const FiltersBaseFilter = () => import('../../components/global/Filters/BaseFilter.vue' /* webpackChunkName: "components/filters-base-filter" */).then(c => wrapFunctional(c.default || c))
export const FiltersDateFilter = () => import('../../components/global/Filters/DateFilter.vue' /* webpackChunkName: "components/filters-date-filter" */).then(c => wrapFunctional(c.default || c))
export const FiltersFilterLabel = () => import('../../components/global/Filters/FilterLabel.vue' /* webpackChunkName: "components/filters-filter-label" */).then(c => wrapFunctional(c.default || c))
export const FiltersFilterWrapper = () => import('../../components/global/Filters/FilterWrapper.vue' /* webpackChunkName: "components/filters-filter-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FiltersInfo = () => import('../../components/global/Filters/FiltersInfo.vue' /* webpackChunkName: "components/filters-info" */).then(c => wrapFunctional(c.default || c))
export const FiltersFunctions = () => import('../../components/global/Filters/functions.js' /* webpackChunkName: "components/filters-functions" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/global/Loading/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const TableCellClickWrapper = () => import('../../components/global/Table/CellClickWrapper.vue' /* webpackChunkName: "components/table-cell-click-wrapper" */).then(c => wrapFunctional(c.default || c))
export const TableStylesDef = () => import('../../components/global/Table/StylesDef.js' /* webpackChunkName: "components/table-styles-def" */).then(c => wrapFunctional(c.default || c))
export const TableDraggableRows = () => import('../../components/global/Table/draggable-rows.vue' /* webpackChunkName: "components/table-draggable-rows" */).then(c => wrapFunctional(c.default || c))
export const TableDraggableTable = () => import('../../components/global/Table/draggable-table.vue' /* webpackChunkName: "components/table-draggable-table" */).then(c => wrapFunctional(c.default || c))
export const ToastsMessage = () => import('../../components/global/toasts/Message.vue' /* webpackChunkName: "components/toasts-message" */).then(c => wrapFunctional(c.default || c))
export const PermissionWrapperTimeOffIsAdmin = () => import('../../components/global/PermissionWrapper/TimeOff/isAdmin.vue' /* webpackChunkName: "components/permission-wrapper-time-off-is-admin" */).then(c => wrapFunctional(c.default || c))
export const PermissionWrapperTimeOffIsManager = () => import('../../components/global/PermissionWrapper/TimeOff/isManager.vue' /* webpackChunkName: "components/permission-wrapper-time-off-is-manager" */).then(c => wrapFunctional(c.default || c))
export const DashboardFilters = () => import('../../components/dashboard/DashboardFilters.vue' /* webpackChunkName: "components/dashboard-filters" */).then(c => wrapFunctional(c.default || c))
export const DashboardHeader = () => import('../../components/dashboard/DashboardHeader.vue' /* webpackChunkName: "components/dashboard-header" */).then(c => wrapFunctional(c.default || c))
export const TimeoffBackupApproverRow = () => import('../../components/timeoff/BackupApproverRow.vue' /* webpackChunkName: "components/timeoff-backup-approver-row" */).then(c => wrapFunctional(c.default || c))
export const TimeoffBalance = () => import('../../components/timeoff/Balance.vue' /* webpackChunkName: "components/timeoff-balance" */).then(c => wrapFunctional(c.default || c))
export const DashboardCommonApprovalStatusKeys = () => import('../../components/dashboard/common/approvalStatusKeys.js' /* webpackChunkName: "components/dashboard-common-approval-status-keys" */).then(c => wrapFunctional(c.default || c))
export const DashboardCommonDisableReasonKeys = () => import('../../components/dashboard/common/disableReasonKeys.js' /* webpackChunkName: "components/dashboard-common-disable-reason-keys" */).then(c => wrapFunctional(c.default || c))
export const DashboardCommonStatuses = () => import('../../components/dashboard/common/statuses.js' /* webpackChunkName: "components/dashboard-common-statuses" */).then(c => wrapFunctional(c.default || c))
export const DashboardCommonSyncStatuses = () => import('../../components/dashboard/common/syncStatuses.js' /* webpackChunkName: "components/dashboard-common-sync-statuses" */).then(c => wrapFunctional(c.default || c))
export const DashboardFiltersProjectFilter = () => import('../../components/dashboard/filters/ProjectFilter.vue' /* webpackChunkName: "components/dashboard-filters-project-filter" */).then(c => wrapFunctional(c.default || c))
export const DashboardModalsAddToProject = () => import('../../components/dashboard/modals/AddToProject.vue' /* webpackChunkName: "components/dashboard-modals-add-to-project" */).then(c => wrapFunctional(c.default || c))
export const DashboardModalsApproveProject = () => import('../../components/dashboard/modals/ApproveProject.vue' /* webpackChunkName: "components/dashboard-modals-approve-project" */).then(c => wrapFunctional(c.default || c))
export const DashboardModalsGoToProject = () => import('../../components/dashboard/modals/GoToProject.vue' /* webpackChunkName: "components/dashboard-modals-go-to-project" */).then(c => wrapFunctional(c.default || c))
export const DashboardModalsRequestApproval = () => import('../../components/dashboard/modals/RequestApproval.vue' /* webpackChunkName: "components/dashboard-modals-request-approval" */).then(c => wrapFunctional(c.default || c))
export const DashboardModalsReviewProjectApprovals = () => import('../../components/dashboard/modals/ReviewProjectApprovals.vue' /* webpackChunkName: "components/dashboard-modals-review-project-approvals" */).then(c => wrapFunctional(c.default || c))
export const DashboardModalsUnApproveProject = () => import('../../components/dashboard/modals/UnApproveProject.vue' /* webpackChunkName: "components/dashboard-modals-un-approve-project" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesMyProjectsFieldsDef = () => import('../../components/dashboard/tables/MyProjectsFieldsDef.js' /* webpackChunkName: "components/dashboard-tables-my-projects-fields-def" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesMyProjectsTable = () => import('../../components/dashboard/tables/MyProjectsTable.vue' /* webpackChunkName: "components/dashboard-tables-my-projects-table" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesProjectsTable = () => import('../../components/dashboard/tables/ProjectsTable.vue' /* webpackChunkName: "components/dashboard-tables-projects-table" */).then(c => wrapFunctional(c.default || c))
export const TimeoffModalsApproveError = () => import('../../components/timeoff/modals/ApproveError.vue' /* webpackChunkName: "components/timeoff-modals-approve-error" */).then(c => wrapFunctional(c.default || c))
export const TimeoffModalsCancelRequest = () => import('../../components/timeoff/modals/CancelRequest.vue' /* webpackChunkName: "components/timeoff-modals-cancel-request" */).then(c => wrapFunctional(c.default || c))
export const TimeoffModalsDeleteTimeOffRequest = () => import('../../components/timeoff/modals/DeleteTimeOffRequest.vue' /* webpackChunkName: "components/timeoff-modals-delete-time-off-request" */).then(c => wrapFunctional(c.default || c))
export const TimeoffModalsDeletedRequest = () => import('../../components/timeoff/modals/DeletedRequest.vue' /* webpackChunkName: "components/timeoff-modals-deleted-request" */).then(c => wrapFunctional(c.default || c))
export const TimeoffModalsManagersError = () => import('../../components/timeoff/modals/ManagersError.vue' /* webpackChunkName: "components/timeoff-modals-managers-error" */).then(c => wrapFunctional(c.default || c))
export const TimeoffModalsRejectRequest = () => import('../../components/timeoff/modals/RejectRequest.vue' /* webpackChunkName: "components/timeoff-modals-reject-request" */).then(c => wrapFunctional(c.default || c))
export const TimeoffRequestFormAdminPart = () => import('../../components/timeoff/request-form/AdminPart.vue' /* webpackChunkName: "components/timeoff-request-form-admin-part" */).then(c => wrapFunctional(c.default || c))
export const TimeoffRequestFormDatePickers = () => import('../../components/timeoff/request-form/DatePickers.vue' /* webpackChunkName: "components/timeoff-request-form-date-pickers" */).then(c => wrapFunctional(c.default || c))
export const TimeoffRequestFormDaysRange = () => import('../../components/timeoff/request-form/DaysRange.vue' /* webpackChunkName: "components/timeoff-request-form-days-range" */).then(c => wrapFunctional(c.default || c))
export const TimeoffRequestFormPerDayInfo = () => import('../../components/timeoff/request-form/PerDayInfo.vue' /* webpackChunkName: "components/timeoff-request-form-per-day-info" */).then(c => wrapFunctional(c.default || c))
export const TimeoffRequestForm = () => import('../../components/timeoff/request-form/RequestForm.vue' /* webpackChunkName: "components/timeoff-request-form" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesBusinessUnitRequestsFieldsDef = () => import('../../components/timeoff/tables/BusinessUnitRequestsFieldsDef.js' /* webpackChunkName: "components/timeoff-tables-business-unit-requests-fields-def" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesBusinessUnitRequestsTable = () => import('../../components/timeoff/tables/BusinessUnitRequestsTable.vue' /* webpackChunkName: "components/timeoff-tables-business-unit-requests-table" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesMyRequestsFieldsDef = () => import('../../components/timeoff/tables/MyRequestsFieldsDef.js' /* webpackChunkName: "components/timeoff-tables-my-requests-fields-def" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesMyRequestsTable = () => import('../../components/timeoff/tables/MyRequestsTable.vue' /* webpackChunkName: "components/timeoff-tables-my-requests-table" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesTeamsRequestsFieldsDef = () => import('../../components/timeoff/tables/TeamsRequestsFieldsDef.js' /* webpackChunkName: "components/timeoff-tables-teams-requests-fields-def" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesTeamsRequestsTable = () => import('../../components/timeoff/tables/TeamsRequestsTable.vue' /* webpackChunkName: "components/timeoff-tables-teams-requests-table" */).then(c => wrapFunctional(c.default || c))
export const TimeoffToastsSuccessSubmited = () => import('../../components/timeoff/toasts/Success-submited.vue' /* webpackChunkName: "components/timeoff-toasts-success-submited" */).then(c => wrapFunctional(c.default || c))
export const TimeoffToastsTeamsRequestsReject = () => import('../../components/timeoff/toasts/TeamsRequests-Reject.vue' /* webpackChunkName: "components/timeoff-toasts-teams-requests-reject" */).then(c => wrapFunctional(c.default || c))
export const DashboardModalsInfoWarningModalsInformationPopUp = () => import('../../components/dashboard/modals/InfoWarningModals/InformationPopUp.vue' /* webpackChunkName: "components/dashboard-modals-info-warning-modals-information-pop-up" */).then(c => wrapFunctional(c.default || c))
export const DashboardModalsInfoWarningPopUp = () => import('../../components/dashboard/modals/InfoWarningModals/WarningPopUp.vue' /* webpackChunkName: "components/dashboard-modals-info-warning-pop-up" */).then(c => wrapFunctional(c.default || c))
export const DashboardModalsModalFields = () => import('../../components/dashboard/modals/modal-components/ModalFields.vue' /* webpackChunkName: "components/dashboard-modals-modal-fields" */).then(c => wrapFunctional(c.default || c))
export const DashboardModalsModalHeader = () => import('../../components/dashboard/modals/modal-components/ModalHeader.vue' /* webpackChunkName: "components/dashboard-modals-modal-header" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsCommonRefresh = () => import('../../components/dashboard/project-details/Common/Refresh.vue' /* webpackChunkName: "components/dashboard-project-details-common-refresh" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsCommonValidationInfo = () => import('../../components/dashboard/project-details/Common/ValidationInfo.vue' /* webpackChunkName: "components/dashboard-project-details-common-validation-info" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsOverview = () => import('../../components/dashboard/project-details/overview/overview.vue' /* webpackChunkName: "components/dashboard-project-details-overview" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectApproval = () => import('../../components/dashboard/project-details/project-approvals/ProjectApproval.vue' /* webpackChunkName: "components/dashboard-project-approval" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectApprovalApprover = () => import('../../components/dashboard/project-details/project-approvals/ProjectApprovalApprover.vue' /* webpackChunkName: "components/dashboard-project-approval-approver" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectApprovalDateTime = () => import('../../components/dashboard/project-details/project-approvals/ProjectApprovalDateTime.vue' /* webpackChunkName: "components/dashboard-project-approval-date-time" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectApprovalPhase = () => import('../../components/dashboard/project-details/project-approvals/ProjectApprovalPhase.vue' /* webpackChunkName: "components/dashboard-project-approval-phase" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectApprovalStatus = () => import('../../components/dashboard/project-details/project-approvals/ProjectApprovalStatus.vue' /* webpackChunkName: "components/dashboard-project-approval-status" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectApprovals = () => import('../../components/dashboard/project-details/project-approvals/ProjectApprovals.vue' /* webpackChunkName: "components/dashboard-project-approvals" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectSubApproval = () => import('../../components/dashboard/project-details/project-approvals/ProjectSubApproval.vue' /* webpackChunkName: "components/dashboard-project-sub-approval" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectSyncValidation = () => import('../../components/dashboard/project-details/project-sync-validation/ProjectSyncValidation.vue' /* webpackChunkName: "components/dashboard-project-sync-validation" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsProjectSyncValidationFields = () => import('../../components/dashboard/project-details/project-sync-validation/fields.js' /* webpackChunkName: "components/dashboard-project-details-project-sync-validation-fields" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsTaskValidation = () => import('../../components/dashboard/project-details/task-validation/TaskValidation.vue' /* webpackChunkName: "components/dashboard-project-details-task-validation" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsTaskValidationFields = () => import('../../components/dashboard/project-details/task-validation/fields.js' /* webpackChunkName: "components/dashboard-project-details-task-validation-fields" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsTaskValidationFunctions = () => import('../../components/dashboard/project-details/task-validation/functions.js' /* webpackChunkName: "components/dashboard-project-details-task-validation-functions" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesCommonBaseProjectTable = () => import('../../components/dashboard/tables/common/BaseProjectTable.vue' /* webpackChunkName: "components/dashboard-tables-common-base-project-table" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesCommonBaseProjectTableFilters = () => import('../../components/dashboard/tables/common/BaseProjectTableFilters.vue' /* webpackChunkName: "components/dashboard-tables-common-base-project-table-filters" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesCommonSyncValidation = () => import('../../components/dashboard/tables/common/SyncValidation.vue' /* webpackChunkName: "components/dashboard-tables-common-sync-validation" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesCommonData = () => import('../../components/dashboard/tables/common/data.js' /* webpackChunkName: "components/dashboard-tables-common-data" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesCommonFunctions = () => import('../../components/dashboard/tables/common/functions.js' /* webpackChunkName: "components/dashboard-tables-common-functions" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesFieldsClientField = () => import('../../components/dashboard/tables/fields/ClientField.vue' /* webpackChunkName: "components/dashboard-tables-fields-client-field" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesFieldsLeaderField = () => import('../../components/dashboard/tables/fields/LeaderField.vue' /* webpackChunkName: "components/dashboard-tables-fields-leader-field" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesFieldsProjectSyncValidationField = () => import('../../components/dashboard/tables/fields/ProjectSyncValidationField.vue' /* webpackChunkName: "components/dashboard-tables-fields-project-sync-validation-field" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesFieldsStatusField = () => import('../../components/dashboard/tables/fields/StatusField.vue' /* webpackChunkName: "components/dashboard-tables-fields-status-field" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesFieldsTasksSyncValidationField = () => import('../../components/dashboard/tables/fields/TasksSyncValidationField.vue' /* webpackChunkName: "components/dashboard-tables-fields-tasks-sync-validation-field" */).then(c => wrapFunctional(c.default || c))
export const TimeoffModalsRequestDetails = () => import('../../components/timeoff/modals/request-details/RequestDetails.vue' /* webpackChunkName: "components/timeoff-modals-request-details" */).then(c => wrapFunctional(c.default || c))
export const TimeoffModalsRequestDetailsUserRequestInfo = () => import('../../components/timeoff/modals/request-details/UserRequestInfo.vue' /* webpackChunkName: "components/timeoff-modals-request-details-user-request-info" */).then(c => wrapFunctional(c.default || c))
export const TimeoffRequestFormCommonData = () => import('../../components/timeoff/request-form/Common/data.js' /* webpackChunkName: "components/timeoff-request-form-common-data" */).then(c => wrapFunctional(c.default || c))
export const TimeoffRequestFormCommonFunctions = () => import('../../components/timeoff/request-form/Common/functions.js' /* webpackChunkName: "components/timeoff-request-form-common-functions" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesCommonPaginationOptions = () => import('../../components/timeoff/tables/common/PaginationOptions.vue' /* webpackChunkName: "components/timeoff-tables-common-pagination-options" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesCommonData = () => import('../../components/timeoff/tables/common/data.js' /* webpackChunkName: "components/timeoff-tables-common-data" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesCommonFunctions = () => import('../../components/timeoff/tables/common/functions.js' /* webpackChunkName: "components/timeoff-tables-common-functions" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesFieldsConsultantField = () => import('../../components/timeoff/tables/fields/ConsultantField.vue' /* webpackChunkName: "components/timeoff-tables-fields-consultant-field" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesFieldsDateField = () => import('../../components/timeoff/tables/fields/DateField.vue' /* webpackChunkName: "components/timeoff-tables-fields-date-field" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesFieldsManagersField = () => import('../../components/timeoff/tables/fields/ManagersField.vue' /* webpackChunkName: "components/timeoff-tables-fields-managers-field" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesFieldsStatusField = () => import('../../components/timeoff/tables/fields/StatusField.vue' /* webpackChunkName: "components/timeoff-tables-fields-status-field" */).then(c => wrapFunctional(c.default || c))
export const TimeoffTablesFieldsTimeOffTypeField = () => import('../../components/timeoff/tables/fields/TimeOffTypeField.vue' /* webpackChunkName: "components/timeoff-tables-fields-time-off-type-field" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsOverviewSectionsBudget = () => import('../../components/dashboard/project-details/overview/sections/Budget.vue' /* webpackChunkName: "components/dashboard-project-details-overview-sections-budget" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetails = () => import('../../components/dashboard/project-details/overview/sections/Details.vue' /* webpackChunkName: "components/dashboard-project-details" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsOverviewSectionsLinks = () => import('../../components/dashboard/project-details/overview/sections/Links.vue' /* webpackChunkName: "components/dashboard-project-details-overview-sections-links" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsOverviewSectionsReportingClassifications = () => import('../../components/dashboard/project-details/overview/sections/ReportingClassifications.vue' /* webpackChunkName: "components/dashboard-project-details-overview-sections-reporting-classifications" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsOverviewSectionsStatus = () => import('../../components/dashboard/project-details/overview/sections/Status.vue' /* webpackChunkName: "components/dashboard-project-details-overview-sections-status" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsOverviewSectionsCommon = () => import('../../components/dashboard/project-details/overview/sections/common.js' /* webpackChunkName: "components/dashboard-project-details-overview-sections-common" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsProjectSyncValidationFieldsErrorField = () => import('../../components/dashboard/project-details/project-sync-validation/fields/errorField.vue' /* webpackChunkName: "components/dashboard-project-details-project-sync-validation-fields-error-field" */).then(c => wrapFunctional(c.default || c))
export const DashboardProjectDetailsTaskValidationFieldsErrorField = () => import('../../components/dashboard/project-details/task-validation/fields/errorField.vue' /* webpackChunkName: "components/dashboard-project-details-task-validation-fields-error-field" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesCommonIconButtonsAddToProjectButton = () => import('../../components/dashboard/tables/common/icon-buttons/AddToProjectButton.vue' /* webpackChunkName: "components/dashboard-tables-common-icon-buttons-add-to-project-button" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesCommonIconButtonsBlockProjectFunction = () => import('../../components/dashboard/tables/common/icon-buttons/BlockProjectFunction.vue' /* webpackChunkName: "components/dashboard-tables-common-icon-buttons-block-project-function" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesCommonIconButtonsGoToProjectApprovals = () => import('../../components/dashboard/tables/common/icon-buttons/GoToProjectApprovals.vue' /* webpackChunkName: "components/dashboard-tables-common-icon-buttons-go-to-project-approvals" */).then(c => wrapFunctional(c.default || c))
export const DashboardTablesCommonIconButtonsProjectLinkButton = () => import('../../components/dashboard/tables/common/icon-buttons/ProjectLinkButton.vue' /* webpackChunkName: "components/dashboard-tables-common-icon-buttons-project-link-button" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

import { render, staticRenderFns } from "./dashboard-details.vue?vue&type=template&id=0016e0ea&scoped=true&"
import script from "./dashboard-details.vue?vue&type=script&lang=js&"
export * from "./dashboard-details.vue?vue&type=script&lang=js&"
import style0 from "./dashboard-details.vue?vue&type=style&index=0&id=0016e0ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_559ffc97fd41de05d12663d7fb949156/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0016e0ea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/usr/src/nuxt-app/components/global/Loading/Loading.vue').default,Header: require('/usr/src/nuxt-app/components/global/Header.vue').default,DashboardHeader: require('/usr/src/nuxt-app/components/dashboard/DashboardHeader.vue').default,Button: require('/usr/src/nuxt-app/components/global/Button/index.vue').default,StatusDot: require('/usr/src/nuxt-app/components/global/StatusDot.vue').default,Tabs: require('/usr/src/nuxt-app/components/global/Tabs.vue').default,AccessWrapper: require('/usr/src/nuxt-app/components/global/AccessWrapper/index.vue').default})

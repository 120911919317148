//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapGetters, mapActions } from 'vuex'
import Header from '~/components/global/Header'
import Balance from '~/components/timeoff/Balance'
import Button from '~/components/global/Button'
import Tabs from '~/components/global/Tabs'
import FiltersInfo from '~/components/global/Filters/FiltersInfo'
import Loading from '~/components/global/Loading/Loading'
import DeletedRequest from '~/components/timeoff/modals/DeletedRequest'
import { capitalizeFirstLetter, pageHeaders } from '~/layouts/Common/functions'
import AccessWrapper from '~/components/global/AccessWrapper'

export default {
  components: {
    AccessWrapper,
    Loading,
    Button,
    Balance,
    Header,
    DeletedRequest,
    Tabs,
    FiltersInfo,
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          title: "Team's requests",
          page: '/timeoff/team-requests',
          onlyManager: true,
        },
        {
          id: 2,
          title: 'Approved team’s requests',
          page: '/timeoff/approved-requests',
          onlyManager: true,
        },
        {
          id: 3,
          title: 'My requests',
          page: '/timeoff/my-requests',
        },
        {
          id: 4,
          title: 'Business unit requests',
          page: '/timeoff/admin-requests',
          onlyAdmin: true,
        },
      ],
      filters: {
        amountFilters: 0,
        totalItems: 0,
      },
    }
  },
  head() {
    const header = capitalizeFirstLetter(this.$route.fullPath.split('/')[2])
    return {
      title: `${header} | ${pageHeaders.timeOff}`,
    }
  },
  computed: {
    ...mapGetters({
      getLoading: 'global/loading/getLoading',
      getAccountMembership: 'global/accountMembership/getAccountMembership',
      timeOffDetails: 'timeoff/requests/getTimeOffDetails',
    }),
  },
  watch: {
    $route() {
      this.RequestDetailsModal()
    },
  },
  mounted() {
    this.fetchUsers()
    this.fetchAccountMembership().then(() => {
      this.RequestDetailsModal()
    })
    this.setLoading(false)
  },
  methods: {
    ...mapActions({
      fetchUsers: 'timeoff/users/fetchUsers',
      fetchAccountMembership: 'global/accountMembership/fetchAccountMembership',
      fetchTimeOffDetails: 'timeoff/requests/fetchTimeOffDetails',
    }),
    ...mapMutations({
      setLoading: 'global/loading/setLoading',
    }),
    openDetailsModal(request, requestType) {
      this.$modal.show('request-details', {
        request,
        requestType,
      })
    },
    RequestDetailsModal() {
      const currentPageName = this.$route.name.slice(8)
      const modalId = this.$route.query.modal
      if (modalId) {
        this.setLoading(true)
        this.fetchTimeOffDetails(modalId)
          .then((result) => {
            // if current user was selected as a manager by at least 1 consultant, he/she is team lead
            const userIsTeamLead = this.getAccountMembership.managees.length
            const request = result.data
            const requestIsApproved =
              request.status === this.$config.TIME_OFF_STATUSES.Approved

            // If request approved and user is team lead, user will be redirected to approved requests page
            if (
              requestIsApproved &&
              userIsTeamLead &&
              currentPageName === 'team-requests'
            ) {
              this.setLoading(true)
              this.$router.push(
                {
                  path: '/timeoff/approved-requests',
                  query: { modal: this.$route.query.modal },
                },
                () => {
                  this.setLoading(false)
                  this.openDetailsModal(request, 'approved-requests')
                }
              )
            } else {
              this.openDetailsModal(request, currentPageName)
            }
            this.setLoading(false)
          })
          .catch((error) => {
            this.setLoading(false)
            this.$modal.show('deleted-request')
            this.$log.warn(error.response)
            throw error
          })
      }
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import InputText from '~/components/global/InputText.vue'
import RejectIcon from '~/assets/icons/reject-icon.svg'

export default {
  name: 'SearchPanel',
  components: {
    InputText,
    RejectIcon,
  },
  data() {
    return {
      searchQuery: '',
    }
  },
  created() {
    this.$nuxt.$on('clearSearchQuery', () => {
      this.clearSearch()
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('clearSearchQuery')
  },
  methods: {
    ...mapActions({
      updateSearchQuery: 'global/filters/updateSearchQuery',
      clearSearchQuery: 'global/filters/clearSearchQuery',
    }),
    onSearch() {
      this.updateSearchQuery(this.searchQuery)
    },
    clearSearch() {
      this.searchQuery = ''
      this.clearSearchQuery()
    },
  },
}

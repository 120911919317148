//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @vue-prop {String} labelText - Label for the radio option
 * @vue-prop {String} id - ID for the radio option
 * @vue-prop {String} name - Name for the radio option
 * @vue-prop {String} labelClasses - classes for label
 * @vue-prop {Boolean} [disabled=false] - Attr for apply disabled styles
 * @vue-data {String} cValue - Value for the radio option
 * @vue-event {String} checked - Emit return selectet option
 * @vue-data {Object} v - Validation for the input
 */
//
export default {
  name: 'InputRadioCheck',
  props: {
    /**
     * Label for the radio option
     */
    labelText: {
      type: String,
      default: '',
    },
    /**
     * ID for the radio option
     */
    id: {
      type: [Number, String],
      default: null,
    },
    /**
     * Name for the radio option
     */
    name: {
      type: String,
      default: '',
    },
    /**
     * [disabled=false] - Attr for apply disabled styles
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Value for the radio option
     */
    cValue: {
      type: [Number, String],
      default: '',
    },
    /**
     * Validation for the input
     */
    v: {
      type: Object,
      default: () => {
        return {}
      },
    },
    /**
     * Type of input: radio or checkbox
     */
    inputType: {
      type: String,
      default: 'Radio',
    },
    /**
     * classes for label
     */
    labelClasses: {
      type: String,
      default: '',
    },
  },
  computed: {
    vchecked: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    input(e) {
      this.$emit('checked', e.target.value)
      if (this.v.$touch) {
        this.v.$touch()
      }
    },
  },
}

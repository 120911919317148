export default function ({ $axios, error: nuxtError }) {
  $axios.onError((error) => {
    const endpointURL = error.response.config.url
    const requestMethod = error.response.config.method
    const requestData = error.response.config.data
    const requestStatus = error.response.status
    const isAuthRequest = error.response.config.url.includes(
      'api/session/getuser'
    )

    // Handle errors if it's not timeoff request and it's not 404/403,
    // because for timeoff requests with 404/403 we show modal "deleted request"
    if (
      endpointURL.includes('/api/TimeOff/') &&
      requestMethod === 'get' &&
      !requestData &&
      requestStatus !== 401
    ) {
      // Skip error page render for timeoff details
      return false
    } else if (isAuthRequest) {
      // Skip error page render for auth request (we can get this error on other requests)
      return false
    } else if (
      (requestStatus === 400 &&
        endpointURL.includes('/api/TimeOff/Approve/')) ||
      (requestStatus === 409 && endpointURL.includes('/api/Projects/Invite')) ||
      (requestStatus === 409 &&
        endpointURL.includes('api/Projects/Approvals/Approve'))
    ) {
      // Skip error page render for approving error pop up
      return false
    } else {
      // Provide info to nuxt error func for page rendering (layouts/errorField.vue)
      nuxtError({
        statusCode: requestStatus,
        message: error.message,
      })
      return Promise.reject(error)
    }
  })
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import FilterWrapper from '~/components/global/Filters/FilterWrapper'
import {
  isAppliableFilterMultiple,
  isAppliableFilterSingle,
} from '~/components/global/Filters/functions.js'

/**
 * @vue-props {String} filterName - name of a filter
 * @vue-props {Array} options - list of options in filter
 * @vue-props {String} inputType - type of input-radio-checkbox component
 * @vue-props {String} storeName - name of a dynamically created vuex store
 * @vue-props {Boolean} multiple - if true - multiple selection in filter, if false - single selection
 * @vue-props {String} noData - Text to show if no data founded
 * @vue-props {String} placeholder - placeholder of an input field in multiselect
 * @vue-props {String} theme - name of css class to redefine styles
 * @vue-props {Boolean} isSortable - define if the checked options are appeared on top of the list
 * @vue-data {Array} filterOptions - array of multiselect options that can be sorted when user select or deselect value
 * @vue-props {String} displayType - if list - multiselect plugin is used, if tree - treeselect plugin is used
 * @vue-props {Function} treeNormalizer - used for normalizing source data
 * @vue-props {Number} treeDefaultExpandLevel - how many levels of branch nodes should be automatically expanded when loaded. Set Infinity to make all branch nodes expanded by default.
 * @vue-props {Boolean} isCounted - whether the filter is calculated into the count of applied filters
 */

export default {
  name: 'BaseFilter',
  components: {
    FilterWrapper,
  },
  props: {
    filterName: {
      type: String,
      default: 'Filter',
    },
    options: {
      type: Array,
      default: () => {
        return []
      },
    },
    inputType: {
      type: String,
      default: 'checkbox',
    },
    storeName: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    noData: {
      type: String,
      default: 'No statuses match your search query',
    },
    searchChange: {
      type: Boolean,
      default: false,
    },
    searchEvent: {
      type: String,
      default: '',
    },
    searchTotal: {
      type: [Boolean, Number],
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'filter-tooltip',
    },
    isSortable: {
      type: Boolean,
      default: true,
    },
    displayType: {
      type: String,
      default: 'list',
    },
    treeNormalizer: {
      type: Function,
      default: () => {
        return false
      },
      required: false,
    },
    treeDefaultExpandLevel: {
      type: Number,
      default: 0,
    },
    isCounted: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      filterValues: [],
      treeSingleFilterValue: null,
      isButtonActive: true,
      isFilterCleared: false,
      noOptions: 'No options are available.',
    }
  },
  computed: {
    ...mapGetters({
      getFilter: 'global/dynamicFilters/getFilter',
    }),
    isAppliable() {
      if (this.multiple) {
        return this.filterValues.length === 0 &&
          this.getFilter({
            name: this.storeName,
            defaultValue: [],
          }).length === 0
          ? false
          : isAppliableFilterMultiple(
              this.getFilter({ name: this.storeName, defaultValue: [] }),
              this.filterValues,
              this
            )
      } else {
        const getVilterVuex = this.getFilter({
          name: this.storeName,
          defaultValue: {},
        })
        const vuexData = this.isTreeSelect ? getVilterVuex?.id : getVilterVuex
        return !this.filterValues && !getVilterVuex
          ? false
          : isAppliableFilterSingle(vuexData, this.filterValues, this)
      }
    },
    selected() {
      let selected = 0
      if (this.multiple) {
        selected = this.filterValues.length
      } else if (
        !Array.isArray(this.filterValues) &&
        typeof this.filterValues === 'object' &&
        this.filterValues !== null
      ) {
        selected = 1
      } else if (
        this.isTreeSelect &&
        !Array.isArray(this.filterValues) &&
        this.filterValues
      ) {
        selected = 1
      }
      return selected
    },
    filterOptions() {
      const normalizeOptions = this.normalizeOptionsForSorting()
      return this.isSortable
        ? this.sortOptions(normalizeOptions)
        : normalizeOptions
    },
    isTreeSelect() {
      return this.displayType === 'tree' && !this.multiple
    },
  },
  methods: {
    ...mapActions({
      setFilter: 'global/dynamicFilters/setFilter',
    }),
    fetchOptionsBySearch(query) {
      if (this.searchChange) {
        this.$nuxt.$emit(this.searchEvent, query)
      }
    },
    applyFilter() {
      if (this.isTreeSelect) {
        this.setFilter({
          name: this.storeName,
          value: this.treeSingleFilterValue,
          isCounted: this.isCounted,
        })
      } else {
        this.setFilter({
          name: this.storeName,
          value: this.filterValues,
          isCounted: this.isCounted,
        })
      }
      this.isButtonActive = false
      this.isFilterCleared = false
      this.$nuxt.$emit('onChangePage', 1)
    },
    closePopup() {
      const getVilterVuex = this.getFilter({
        name: this.storeName,
        defaultValue: null,
      })
      this.filterValues =
        this.isTreeSelect && getVilterVuex !== null
          ? getVilterVuex.id
          : getVilterVuex
      this.isButtonActive = false
    },
    clearFilter() {
      if (this.multiple) {
        if (this.filterValues.length > 0) {
          this.filterValues = []
          this.isFilterCleared = true
        }
      } else if (this.filterValues) {
        this.filterValues = null
        if (this.isTreeSelect) {
          this.treeSingleFilterValue = null
        }
        this.isFilterCleared = true
      }
    },
    selectTreeFilter(node) {
      this.treeSingleFilterValue = node
      this.inputFilter(this.treeSingleFilterValue)
      if (node.id === this.filterValues) {
        this.clearFilter()
      }
    },
    inputFilter(value) {
      if (!this.multiple) {
        this.isButtonActive = !(
          this.getFilter({ name: this.storeName, defaultValue: null }) !==
            null &&
          value?.id ===
            this.getFilter({ name: this.storeName, defaultValue: null })?.id
        )
      } else {
        this.isButtonActive = !(
          this.getFilter({ name: this.storeName, defaultValue: [] }).length >
            0 &&
          this.filterValues.length !==
            this.getFilter({ name: this.storeName, defaultValue: [] }).length
        )
        this.filterValues.map((selectedEl) => {
          return this.getFilter({
            name: this.storeName,
            defaultValue: [],
          }).forEach((prevSelectedEl) => {
            if (selectedEl.id !== prevSelectedEl.id) {
              this.isButtonActive = true
            }
          })
        })
      }
    },
    normalizeOptionsForSorting() {
      return this.isTreeSelect
        ? this.options
        : this.options.map((el, i) => {
            const { id, name, fullName, label, ...rest } = el
            if (this.storeName !== 'statusesDashboard') {
              return {
                id: el.id,
                name: el.name || el.fullName || el.label || null,
                ...rest,
              }
            } else {
              return {
                id: el.id,
                name: el.name || el.fullName || null,
                rowNumber: i,
                ...rest,
              }
            }
          })
    },
    sortOptions(normalizeOptions) {
      let uncheckedOptions = [...normalizeOptions]
      let checkedOptions = []
      if (this.multiple && this.filterValues.length) {
        checkedOptions = [...this.filterValues]
      } else if (
        !Array.isArray(this.filterValues) &&
        typeof this.filterValues === 'object' &&
        this.filterValues !== null
      ) {
        checkedOptions = [{ ...this.filterValues }]
      }
      const checkedOptionsIds = checkedOptions.map((el) => el.id)
      if (checkedOptions.length) {
        uncheckedOptions = normalizeOptions.filter((el) => {
          return !checkedOptionsIds.includes(el.id)
        })
      }
      if (this.storeName !== 'statusesDashboard') {
        checkedOptions.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1
          } else if (b.name.toLowerCase() > a.name.toLowerCase()) {
            return -1
          } else {
            return 0
          }
        })
      } else {
        checkedOptions.sort((a, b) => a.rowNumber - b.rowNumber)
      }
      return [...checkedOptions, ...uncheckedOptions]
    },
    onOpenFilter(payload) {
      this.$emit('on-open-filter', payload)
    },
    onTreeResetSearchQuery() {
      if (this.isTreeSelect) {
        this.$refs['treeselect-' + this.filterName].resetSearchQuery()
      }
    },
  },
}

export default function ({ store, app, redirect }, next) {
  if (!store.state.global.accountMembership.accountMembership.managees.length) {
    store
      .dispatch('global/accountMembership/fetchAccountMembership')
      .then((result) => {
        if (
          store.state.global.accountMembership.accountMembership.managees
            .length === 0
        ) {
          store.commit('global/loading/setLoading', false)
          redirect('/')
        } else {
          app.$cookies.remove('redirectURL')
          next()
        }
      })
      .catch((e) => {
        throw e
      })
  } else {
    next()
  }
}

//
//
//
//
//
//
//
//
//
//

import Header from '~/components/global/Header'

export default {
  components: {
    Header,
  },
}

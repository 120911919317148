//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import Header from '~/components/global/Header'
import DashboardHeader from '~/components/dashboard/DashboardHeader'
import Tabs from '~/components/global/Tabs'
import Button from '~/components/global/Button'
import StatusDot from '~/components/global/StatusDot'
import AccessWrapper from '~/components/global/AccessWrapper'
import { pageHeaders } from '~/layouts/Common/functions'

/**
 * @vue-data {Array} tabs - list of tabs that will be rendered
 * @vue-computed {String} projectTitle - concatenating of project client and title
 * @vue-data {Boolean} loading - check if data is loaded
 */

export default {
  name: 'DashboardDetails',
  components: {
    Header,
    DashboardHeader,
    Tabs,
    Button,
    StatusDot,
    AccessWrapper,
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          title: 'Overview',
          page: `/dashboard/project/overview/${this.$route.params.id}`,
        },
        {
          id: 2,
          title: 'Project validation',
          page: `/dashboard/project/project-validation/${this.$route.params.id}`,
        },
        {
          id: 3,
          title: 'Task validation',
          page: `/dashboard/project/task-validation/${this.$route.params.id}`,
        },
      ],
      loading: true,
    }
  },
  head() {
    return {
      title: this.getProjectDetails.title
        ? `${this.getProjectDetails.title} │ ${pageHeaders.projectDashboard}`
        : this.$nuxt.context.app.head.title,
    }
  },
  computed: {
    ...mapGetters({
      getProjectDetails: 'dashboard/requests/getProjectDetails',
      getApprovalAndContainer: 'dashboard/requests/getApprovalAndContainer',
      getIsTaskError: 'dashboard/requests/getIsTaskError',
      getApprovalsPossibility: 'dashboard/requests/getApprovalsPossibility',
    }),
    isTaskError() {
      if (this.getIsTaskError === false || this.getIsTaskError === true) {
        return this.getIsTaskError
      } else
        return (
          this.getProjectDetails &&
          this.getProjectDetails?.taskLinkedValidationInfo !== null &&
          this.getProjectDetails?.taskLinkedValidationInfo?.status === 0
        )
    },
    projectTitle() {
      if (this.getProjectDetails) {
        const result = this.getProjectDetails.client?.name
          ? this.getProjectDetails.client?.name + ':'
          : ''
        return `${result} ${this.getProjectDetails.title}`
      } else return null
    },
    tabsWithErrors() {
      const tabsErrors = this.tabs
      this.getProjectDetails?.isErrors
        ? (tabsErrors[1].isHasErrors = true)
        : (tabsErrors[1].isHasErrors = false)
      tabsErrors[2].isHasErrors = this.isTaskError
      return tabsErrors
    },
  },
  watch: {
    $route() {
      this.approvalProjectModal()
    },
    getProjectDetails() {
      if (!this.$route.query.guid) {
        Object.keys(this.getProjectDetails).length === 0
          ? (this.loading = true)
          : (this.loading = false)
      }
    },
  },
  async beforeMount() {
    this.fetchApprovalsPossibility(this.$route.params.id).then(() => {
      if (this.getApprovalsPossibility) {
        this.tabs.push({
          id: 4,
          title: 'Project approval',
          page: `/dashboard/project/project-approval/${this.$route.params.id}`,
        })
      }
    })
    const guid = this.$route.query.guid
    if (guid) {
      await this.fetchProjectDetails(this.$route.params.id)
        .then(() =>
          this.fetchApprovalAndContainer({
            projectId: this.$route.params.id,
            guid,
          })
        )
        .then(() => {
          this.approvalProjectModal()
        })
      this.loading = false
    }
  },
  methods: {
    ...mapActions({
      fetchProjectDetails: 'dashboard/requests/fetchProjectDetails',
      fetchApprovalAndContainer: 'dashboard/requests/fetchApprovalAndContainer',
      fetchApprovalsPossibility: 'dashboard/requests/fetchApprovalsPossibility',
    }),
    approvalProjectModal() {
      const guid = this.$route.query.guid
      if (guid && this.getApprovalAndContainer?.approval?.status !== 1) {
        let projectName
        if (this.getApprovalAndContainer?.container?.subApprovals.length > 1) {
          projectName =
            this.getApprovalAndContainer.container.name +
            ': ' +
            this.getApprovalAndContainer.approval.name
        } else projectName = this.getApprovalAndContainer?.approval?.name
        this.$modal.show('approve-project-modal', {
          projectName,
          projectId: this.$route.params.id,
          guid,
          description: this.getApprovalAndContainer.approval.description,
          isDisabled: this.getApprovalAndContainer.approval.isDisabled,
          disableReasons: this.getApprovalAndContainer.approval.disableReasons,
        })
      } else if (guid && this.getApprovalAndContainer?.approval?.status === 1) {
        this.$modal.show('info-modal')
      }
    },
  },
}

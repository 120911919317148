/**
 * modify Array to needed format to use it in filter's options
 * @param {Array} arr - array that need to be modified
 * @return {Array} - modified Array
 */
const normalizedArray = function (arr) {
  return arr?.map((el) => {
    return {
      id: el.id,
      name: el.fullName,
    }
  })
}

/**
 * refresh table if some filter changed except in the case when clear all is clicked
 * @param component - vue component where function is executed.
 */
const tableRefresher = function (component) {
  if (!component.getFiltersReset) {
    component.$nextTick(function () {
      component.$refs.vuetable.reload()
    })
  }
}

/**
 * function that compares 2 values (Arrays and not arrays). If arrays have different length, return false
 * @param val1 - first value to compare
 * @param val2 - second value to compare
 * @return {boolean} - the result of comparison
 */

const compareValues = function (val1, val2) {
  if (Array.isArray(val1) && Array.isArray(val2)) {
    return compareArrays(val1, val2)
  } else if (
    (typeof val1 === 'object' && val1 !== null) ||
    (typeof val2 === 'object' && val2 !== null)
  ) {
    return val1?.id === val2?.id
  } else {
    return val1 === val2
  }
}

/**
 * function that compares 2 Arrays. If arrays have different length, return false
 * @param val1 - first Array to compare
 * @param val2 - second Array to compare
 * @return {boolean} - the result of comparison
 */

const compareArrays = function (val1, val2) {
  const serverValues = val1.map((el) => el.id)
  const localValues = val2.map((el) => el.id)
  if (serverValues.length === localValues.length && serverValues.length > 0) {
    let result = true
    for (const value of serverValues) {
      if (!localValues.includes(value)) {
        result = false
        break
      }
    }
    return result
  } else {
    return false
  }
}

/**
 * function that makes apply button in filter popup enabled or disabled. Works only for filter with multiple selection
 * @param vuexData - values in vuex store
 * @param componentData - values in component data
 * @param component - vue component where function is executed
 * @return {boolean|*|boolean} - if true, than apply button in filter popup is enabled, if false - disabled
 */
const isAppliableFilterMultiple = function (
  vuexData,
  componentData,
  component
) {
  const isEqualValues = compareValues(vuexData, componentData)
  return (
    (componentData.length > 0 && component.isButtonActive && !isEqualValues) ||
    (component.isFilterCleared &&
      componentData.length === 0 &&
      vuexData.length > 0 &&
      !isEqualValues) ||
    (componentData.length === 0 && vuexData.length > 0 && !isEqualValues) ||
    (component.isFilterCleared && vuexData.length > 0 && !isEqualValues)
  )
}

/**
 * function that makes apply button in filter popup enabled or disabled. Works only for filter with single selection
 * @param vuexData - values in vuex store
 * @param componentData - values in component data
 * @param component - vue component where function is executed
 * @return {boolean|*|boolean} - if true, than apply button in filter popup is enabled, if false - disabled
 */
const isAppliableFilterSingle = function (vuexData, componentData, component) {
  const isEqualValues = compareValues(vuexData, componentData)
  return (
    (componentData && component.isButtonActive && !isEqualValues) ||
    (component.isFilterCleared &&
      !componentData &&
      vuexData &&
      !isEqualValues) ||
    (!componentData && vuexData && !isEqualValues) ||
    (component.isFilterCleared && vuexData && !isEqualValues)
  )
}

/**
 * function that sort array of objects by alphabetical of option
 * @param {Array} arr - array for sorting
 * @param {String} option - name of option for sorting
 * @return {Array} - sorted array
 */
const sortArrOfObjByAlphabetical = function (arr, option) {
  arr.sort(function (a, b) {
    const optionA = a[option].toUpperCase()
    const optionB = b[option].toUpperCase()
    return optionA.localeCompare(optionB)
  })
}

export {
  normalizedArray,
  tableRefresher,
  compareValues,
  isAppliableFilterMultiple,
  isAppliableFilterSingle,
  sortArrOfObjByAlphabetical,
}

export default {}

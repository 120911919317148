//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'

export default {
  name: 'ProjectFilter',
  data() {
    return {
      options: [
        {
          id: 0,
          name: 'Active projects',
        },
        {
          id: 2,
          name: 'Archived projects',
        },
        {
          id: 1,
          name: 'All projects',
        },
      ],
      filter: {
        Archived: {
          id: 0,
          name: 'Active projects',
        },
      },
    }
  },
  watch: {
    'filter.Archived'(val, oldVal) {
      if (val === null) {
        this.filter.Archived = oldVal
      }
    },
  },
  created() {
    this.$nuxt.$on('setDefaultValueProjectFilter', () => {
      this.updateArchivedFilter(0)
      this.filter.Archived = {
        id: 0,
        name: 'Active projects',
      }
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('setDefaultValueProjectFilter')
  },
  methods: {
    inputFilter(value) {
      if (value) {
        this.updateArchivedFilter(value.id)
      }
    },
    ...mapActions({
      updateArchivedFilter: 'dashboard/filters/updateArchivedFilter',
    }),
  },
}

//
//
//
//
//
//
//
//
//
//
//

/**
 * @vue-props {String} statusColor - color of the dot
 * @vue-props {Number} diameter - diameter of the dot
 */

export default {
  name: 'StatusDot',
  props: {
    statusColor: {
      type: String,
      default: '#000',
    },
    diameter: {
      type: String,
      default: '6px',
    },
  },
}

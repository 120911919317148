import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=ed10073c&scoped=true&"
import script from "./dashboard.vue?vue&type=script&lang=js&"
export * from "./dashboard.vue?vue&type=script&lang=js&"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=ed10073c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_559ffc97fd41de05d12663d7fb949156/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed10073c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/usr/src/nuxt-app/components/global/Header.vue').default,DashboardHeader: require('/usr/src/nuxt-app/components/dashboard/DashboardHeader.vue').default,Tabs: require('/usr/src/nuxt-app/components/global/Tabs.vue').default,SearchPanel: require('/usr/src/nuxt-app/components/global/SearchPanel.vue').default,DashboardFilters: require('/usr/src/nuxt-app/components/dashboard/DashboardFilters.vue').default,FiltersInfo: require('/usr/src/nuxt-app/components/global/Filters/FiltersInfo.vue').default,AccessWrapper: require('/usr/src/nuxt-app/components/global/AccessWrapper/index.vue').default})

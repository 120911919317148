import Vue from 'vue'

export const state = () => ({})

export const actions = {
  setFilter: ({ commit, state }, payload) => {
    // check if store is an Array
    if (payload.isCounted) {
      if (Array.isArray(state[payload.name])) {
        if (!state[payload.name].length && payload.value) {
          commit('global/filters/setAppliedFilters', 1, { root: true })
          commit('global/filters/addAppliedFiltersKeys', payload.name, {
            root: true,
          })
        } else if (!payload.value.length) {
          commit('global/filters/setAppliedFilters', -1, { root: true })
          commit('global/filters/deleteAppliedFiltersKeys', payload.name, {
            root: true,
          })
        }
      } else if (!Array.isArray(state[payload.name])) {
        if (!state[payload.name] && payload.value) {
          commit('global/filters/setAppliedFilters', 1, { root: true })
          commit('global/filters/addAppliedFiltersKeys', payload.name, {
            root: true,
          })
        } else if (!payload.value) {
          commit('global/filters/setAppliedFilters', -1, { root: true })
          commit('global/filters/deleteAppliedFiltersKeys', payload.name, {
            root: true,
          })
        }
      }
      commit('global/filters/setFiltersReset', false, { root: true })
    }
    Vue.set(state, payload.name, payload.value)
  },
  deleteFilters: ({ commit, state, rootGetters }) => {
    const appliedFiltersKeys =
      rootGetters['global/filters/getAppliedFiltersKeys']
    Object.keys(state).forEach((key) => {
      const index = appliedFiltersKeys.indexOf(key)
      if (index > -1) {
        Array.isArray(state[key]) ? (state[key] = []) : (state[key] = null)
      }
    })
    commit('global/filters/deleteStartDateFilter', null, { root: true })
    commit('global/filters/setAppliedFilters', 0, { root: true })
    commit('global/filters/clearAppliedFiltersKeys', null, {
      root: true,
    })
    commit('global/filters/setAmountFilteredItems', 0, { root: true })
    commit('global/filters/setFiltersReset', true, { root: true })
  },
}

export const getters = {
  getFilter: (state) => (payload) => {
    if (typeof state[payload.name] === 'undefined') {
      Vue.set(state, payload.name, payload.defaultValue)
    }
    return state[payload.name]
  },
}

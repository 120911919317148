import { render, staticRenderFns } from "./DeletedRequest.vue?vue&type=template&id=004fca33&scoped=true&"
import script from "./DeletedRequest.vue?vue&type=script&lang=js&"
export * from "./DeletedRequest.vue?vue&type=script&lang=js&"
import style0 from "./DeletedRequest.vue?vue&type=style&index=0&id=004fca33&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_559ffc97fd41de05d12663d7fb949156/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "004fca33",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/nuxt-app/components/global/Button/index.vue').default})

import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=a2f49ba4&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Header.vue?vue&type=style&index=1&id=a2f49ba4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_559ffc97fd41de05d12663d7fb949156/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2f49ba4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/usr/src/nuxt-app/components/global/Logo.vue').default,Button: require('/usr/src/nuxt-app/components/global/Button/index.vue').default,Dropdown: require('/usr/src/nuxt-app/components/global/Dropdown/index.vue').default,Header: require('/usr/src/nuxt-app/components/global/Header.vue').default})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/global/Button'
import ErrorIcon from '~/assets/icons/error-icon.svg'

export default {
  components: { Button, ErrorIcon },
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localError: this.error?.statusCode,
    }
  },
  watch: {
    'error.statusCode'(val, oldVal) {
      if (val !== oldVal) {
        this.localError = oldVal
      }
    },
  },
  methods: {
    // if our url contain info about it's parent, we have to redirect user there,
    // if not, user should go to the root page with list of Apps
    redirectToParent() {
      const currentRoute = this.$nuxt.$route.path
      if (currentRoute.includes('timeoff')) {
        this.$router.push('/timeoff/my-requests')
      } else {
        this.$router.push('/')
      }
    },
  },
}

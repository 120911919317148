const capitalizeFirstLetter = function (string) {
  const arr = string.split('-')
  return arr
    .map((element) => {
      return (
        element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
      )
    })
    .join(' ')
}

const pageHeaders = {
  timeOff: 'Time Off',
  projectDashboard: 'Project Dashboard',
}

export { capitalizeFirstLetter, pageHeaders }

export default {}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @vue-prop {String} labelText - Label for the text input
 * @vue-prop {String} id - ID for the text input
 * @vue-prop {String} [type = text] - text, password, email, number, url, tel, search, range, color, time
 * @vue-prop {String} placeholder Value of placeholder attribute for the text input
 * @vue-prop {String} name - Name for the text input
 * @vue-prop {Boolean} [disabled=false] - Attr for apply disabled styles
 * @vue-data {String} value - Value for the text input
 * @vue-data {String} autocomplete - Give option on || off input autocomplite
 * @vue-data {String} iconClass - Class for specific behavior like a error or calendar
 * @vue-data {String} Classes - Additional classes for styling
 * @vue-data {String} errorPlacement - Place for error message
 * @vue-data {String} errorMsg - Error Message(s) for validation
 * @vue-event {String} input - Emit return input value
 * @vue-data {Object} v - Validation for the input
 * The Text Input
 * @displayName Input Text
 */

import CalendarIcon from '~/assets/icons/calendar-icon.svg'
import SearchIcon from '~/assets/icons/search-icon.svg'

export default {
  name: 'InputText',
  components: {
    CalendarIcon,
    SearchIcon,
  },
  props: {
    /**
     * Label for the text input
     */
    labelText: {
      type: String,
      default: '',
    },
    /**
     * ID for the text input
     */
    id: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * [type = text] - text, password, email, number, url, tel, search, range, color, time
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * placeholder Value of placeholder attribute for the text input
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Name for the text input
     */
    name: {
      type: String,
      default: '',
    },
    /**
     * Give option on || off input autocomplite
     */
    autocomplete: {
      type: String,
      default: 'on',
    },
    /**
     * [disabled=false] - Attr for apply disabled styles
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Class for specific behavior like a error or calendar
     */
    iconClass: {
      type: String,
      default: '',
    },
    /**
     * Additional classes for styling
     */
    classes: {
      type: String,
      default: '',
    },
    /**
     * Value for the text input
     */
    value: {
      type: [Number, String],
      default: '',
    },
    /**
     * Set position for tippy
     */
    zPositionTip: {
      type: Number,
      default: 99,
    },
    /**
     * Error Message(s) for validation
     */
    errorMsg: {
      type: String,
      default: '',
    },
    /**
     * Place for error message
     */
    errorPlacement: {
      type: String,
      default: 'top',
    },
    /**
     * Theme for tooltip message
     */
    tipTheme: {
      type: String,
      default: 'error-tooltip',
    },
    /**
     * Validation for the input
     */
    v: {
      type: Object,
      default: () => {
        return {}
      },
    },
    /**
     * Turn on dynamic validation
     */
    isDynamicValidation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    vname: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    hasErrorWithMessage() {
      return this.v && this.v.$error && this.errorMsg
    },
    hasError() {
      return this.v && this.v.$error
    },
    inputListeners() {
      const vm = this
      return Object.assign({}, this.$listeners, {
        input(event) {
          vm.checkValidation()
        },
      })
    },
  },
  methods: {
    checkValidation() {
      if (typeof this.v === 'object' && this.isDynamicValidation) {
        return this.v.$touch()
      }
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

/**
 * @vue-prop {String} classes - list of css classes to use on tippy component
 * @vue-prop {Number} selected - Number that shows how many points if filter were selected
 * @vue-prop {String} filterName - Name of current filter
 * @vue-prop {Boolean} isAppliable - Value that shows if filter can be applied
 * @vue-prop {String} placement - placement props of tippy component
 * @vue-props {String} theme - name of css class to redefine styles
 * @vue-event {} clear-filter - emit of clearing filter event
 * @vue-event {} apply-filter - emit of applying filter event
 * @vue-event {String} close-popup - emit closing filter
 * @vue-props {Boolean} isCounted - whether the filter is calculated into the count of applied filters
 * @vue-props {String} displayType - if list - multiselect plugin is used, if tree - treeselect plugin is used
 */

export default {
  name: 'FilterWrapper',
  props: {
    classes: {
      type: String,
      default: '',
    },
    selected: {
      type: Number,
      default: 0,
    },
    filterName: {
      type: String,
      default: '',
    },
    isAppliable: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom-start',
    },
    theme: {
      type: String,
      default: 'filter-tooltip',
    },
    isCounted: {
      type: Boolean,
      default: true,
    },
    displayType: {
      type: String,
      default: 'list',
    },
  },
  computed: {
    ...mapGetters({
      getFiltersReset: 'global/filters/getFiltersReset',
    }),
  },
  watch: {
    getFiltersReset(val) {
      if (val === true && this.isCounted) {
        this.$emit('clear-filter')
      }
    },
  },
  methods: {
    close() {
      this.$refs[this.filterName]._data.tip.hide()
      this.$emit('close-popup', this.filterName)
    },
    apply() {
      this.$emit('apply-filter')
      this.close()
    },
    onShowTippy() {
      this.$emit('on-open-filter', this.filterName)
    },
    onHideTippy() {
      if (this.displayType === 'tree') {
        this.$emit('on-tree-reset-search-query')
      }
    },
  },
}

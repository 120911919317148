import { statuses } from '~/components/dashboard/common/statuses'

/**
 * transform vuetable data to needed format
 * @param {Object} response - response from api url
 * @returns {Object} - data that will be rendered in table
 */

const transformer = function transformer(response) {
  return {
    total: response.total,
    current_page: response.currentPage,
    per_page: response.perPage,
    last_page: response.lastPage,
    data: response.data.map((t) => {
      const shortTitle = t.title
      const clientTitle = t.primaryClient ? t.primaryClient.name : ''
      const identificator = t.identifier || ''
      const lead = t.owner ? t.owner.fullName : ''
      return {
        ...t,
        shortTitle,
        clientTitle,
        identificator,
        lead,
        request: t,
      }
    }),
  }
}

/**
 * implements pagination
 * @param {Object} paginationData - data of current page
 * @param {Object} component - vue component where function is executed.
 */

const dataForPagination = function (paginationData, component) {
  if (paginationData.total !== 0) {
    component.$refs.pagination.setPaginationData(paginationData)
  } else {
    paginationData.current_page = 1
    paginationData.last_page = 1
    component.$refs.pagination.setPaginationData(paginationData)
  }
}

/**
 * @param {Object} value - project status object
 * @return {String} - color based on project status
 */

const getStatusColor = function (value) {
  return statuses.find((el) => el.key === value?.key)?.color || '#000'
}

/**
 * Go to project details page
 * @param {Number} id - id of project
 * @param {Object} component - vue component where function is executed.
 */

const goToProjectOverview = function (id, component) {
  const newTab = component.$router.resolve(`/dashboard/project/overview/${id}`)
  window.open(newTab.href, '_blank')
}

export { transformer, dataForPagination, getStatusColor, goToProjectOverview }

export default {}

export const state = () => ({
  startDate: null,
  endDate: null,
  disabledDates: false,
})

export const mutations = {
  setStartDate(state, payload) {
    state.startDate = payload
  },
  setEndDate(state, payload) {
    state.endDate = payload
  },
  setDisabledDates(state, payload) {
    state.disabledDates = payload
  },
}

export const getters = {
  getStartDate(state) {
    return state.startDate
  },
  getEndDate(state) {
    return state.endDate
  },
  getDisabledDates(state) {
    return state.disabledDates
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import isManager from '~/components/global/PermissionWrapper/TimeOff/isManager'
import AccessWrapper from '~/components/global/AccessWrapper'

/**
 * @vue-prop {Array} tabs - list of tabs to render
 */

export default {
  name: 'Tabs',
  components: {
    AccessWrapper,
    isManager,
  },
  props: {
    tabs: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex'
import Header from '~/components/global/Header'
import Loading from '~/components/global/Loading/Loading'

export default {
  components: {
    Loading,
    Header,
  },
  computed: {
    ...mapGetters({
      getLoading: 'global/loading/getLoading',
    }),
  },
  beforeUpdate() {
    this.$nextTick(function () {
      this.setLoading(false)
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'global/loading/setLoading',
    }),
  },
}

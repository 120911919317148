import Vue from 'vue'
import { DateTime } from 'luxon'

export default ({ app, store }, inject) => {
  const timeOffAdmin = Vue.observable({ isAdmin: undefined })

  store.watch(
    (state, getters) => getters['global/auth/getPermissions'],
    (permissions) => {
      timeOffAdmin.isAdmin = permissions.timeOffPermissions.isAdmin
    }
  )

  const timeOffStatus = app.$config.TIME_OFF_STATUSES

  const ruleOf10th = {
    getStartDate() {
      let today = DateTime.fromJSDate(new Date()).toUTC()

      if (timeOffAdmin.isAdmin) {
        return today.minus({ years: 2 })
      }

      if (today.day > 10) {
        today = DateTime.local(today.year, today.month, 1)
      } else {
        const todayMinusMonth = today.minus({ months: 1 })
        today = DateTime.local(todayMinusMonth.year, todayMinusMonth.month, 1)
      }
      return today
    },
    isInvoicedDate(date) {
      if (!(date instanceof Date)) {
        date = DateTime.fromISO(date).toUTC()
      }
      return date.valueOf() < this.getStartDate().valueOf()
    },
    canDelete(timeOff) {
      return (
        (timeOff &&
          (timeOff.status !== timeOffStatus.Approved ||
            !this.isInvoicedDate(timeOff.startDate))) ||
        timeOffAdmin.isAdmin
      )
    },
    canEdit(timeOff) {
      return (
        (timeOff && !this.isInvoicedDate(timeOff.startDate)) ||
        timeOffAdmin.isAdmin
      )
    },
    canApprove(timeOff) {
      return (
        (timeOff &&
          timeOff.status === timeOffStatus.Pending &&
          !this.isInvoicedDate(timeOff.startDate)) ||
        timeOffAdmin.isAdmin
      )
    },
    canReject(timeOff) {
      return (
        (timeOff &&
          timeOff.status !== timeOffStatus.Rejected &&
          !this.isInvoicedDate(timeOff.startDate)) ||
        timeOffAdmin.isAdmin
      )
    },
  }

  Vue.prototype.$ruleOf10th = ruleOf10th
  inject('rule-of-10th', ruleOf10th)
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import BaseFilter from '~/components/global/Filters/BaseFilter.vue'
import FilterLabel from '~/components/global/Filters/FilterLabel'
import ProjectFilter from '~/components/dashboard/filters/ProjectFilter'

export default {
  name: 'DashboardFilters',
  components: {
    BaseFilter,
    FilterLabel,
    ProjectFilter,
  },
  data() {
    return {
      circleOptions: [],
      departmentOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      getFetchedCircles: 'dashboard/requests/getFetchedCircles',
      getFetchedDepartments: 'dashboard/requests/getFetchedDepartments',
    }),
  },
  watch: {
    getFetchedCircles(val) {
      this.circleOptions = val.data
    },
    getFetchedDepartments(val) {
      this.departmentOptions = val.data
    },
  },
  async beforeMount() {
    await this.fetchFilterValues()
  },
  created() {
    this.$nuxt.$on('clearDashboardFilters', this.onClearDashboardFilters)
  },
  beforeDestroy() {
    this.$nuxt.$off('clearDashboardFilters')
  },
  methods: {
    ...mapActions({
      fetchFilterValues: 'dashboard/requests/fetchFilterValues',
      setFilter: 'global/dynamicFilters/setFilter',
    }),
    onChangePage(page) {
      this.$nuxt.$emit('onChangePage', page)
    },
    treeNormalizer(node) {
      return {
        id: node.id,
        label: node.name,
        children:
          node.directDescedents && node.directDescedents.length
            ? node.directDescedents
            : 0,
      }
    },
    onClearDashboardFilters() {
      this.$refs['base-filter-circles-filter-dashboard'].clearFilter()
      this.$refs['base-filter-departments-filter-dashboard'].clearFilter()
      this.setFilter({
        name: 'departmentsDashboard',
        value: null,
        isCounted: false,
      })
      this.setFilter({
        name: 'circlesDashboard',
        value: null,
        isCounted: false,
      })
    },
  },
}

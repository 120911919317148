//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import Dropdown from '~/components/global/Dropdown'

export default {
  name: 'Header',
  components: { Dropdown },
  computed: {
    ...mapGetters({
      currentUser: 'global/auth/getCurrentUser',
    }),
  },
  methods: {
    ...mapActions({
      logout: 'global/auth/logout',
    }),
  },
}

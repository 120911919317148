//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import Header from '~/components/global/Header'
import DashboardHeader from '~/components/dashboard/DashboardHeader'
import DashboardFilters from '~/components/dashboard/DashboardFilters'
import InfoIcon from '~/assets/icons/info.svg'
import Tabs from '~/components/global/Tabs'
import SearchPanel from '~/components/global/SearchPanel'
import FiltersInfo from '~/components/global/Filters/FiltersInfo'
import {
  capitalizeFirstLetter,
  pageHeaders,
} from '~/layouts/Common/functions.js'
import AccessWrapper from '~/components/global/AccessWrapper'

export default {
  components: {
    AccessWrapper,
    Header,
    DashboardHeader,
    DashboardFilters,
    InfoIcon,
    FiltersInfo,
    Tabs,
    SearchPanel,
  },
  head() {
    const header = capitalizeFirstLetter(this.$route.fullPath.split('/')[2])
    return {
      title: `${header} | ${pageHeaders.projectDashboard}`,
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'global/auth/getPermissions',
    }),
    tabs() {
      const projectPermissions = this.permissions.projectPermissions
      const availableTabs = []

      if (projectPermissions.isAdmin) {
        availableTabs.push({
          id: 1,
          title: 'Projects',
          page: '/dashboard/projects',
        })
      }
      if (projectPermissions.isPm) {
        availableTabs.push({
          id: 2,
          title: 'My projects',
          page: '/dashboard/my-projects',
        })
      }

      return availableTabs
    },
  },
}

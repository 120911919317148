import Vue from 'vue'
// All global filters live here
import { DateTime } from 'luxon'

/**
 * Add "s" letter if value is plural
 * @param {number} value - Number that will be modified
 * @param {string} type - the text to which the letter s must be added
 */

export const singularOrPlural = Vue.filter(
  'singularOrPlural',
  (value, type) => {
    return value === 1 ? `${value} ${type}` : `${value} ${type}s`
  }
)

/**
 * format date to format type
 * @param {date} date - date value that must be formatted
 * @param {string} format - date format type
 */

export const dateFormat = Vue.filter(
  'dateFormat',
  (date, format = 'LLL dd, yyyy') => {
    const localDateTime = DateTime.now()
    const isUniversalZone = localDateTime.zone.universal
    const localDateTimeZoneOffset = localDateTime.offset

    if (date) {
      if (!isUniversalZone) {
        date = DateTime.fromISO(date, { zone: 'utc' }).plus({
          minutes: localDateTimeZoneOffset,
        })
      } else {
        date = DateTime.fromISO(date)
      }
    }

    return date
      ? date.toFormat(format).replace('AM', 'am').replace('PM', 'pm')
      : ''
  }
)

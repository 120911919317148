/**
 * @vuex-state {string} loggedIn - Initial auth value
 * @vuex-state {array} currentUser - Initial user value
 * @vuex-action {Promise} getCurrentUser - Send request on API and write result into store
 * @vuex-action {Promise} fetchAccessKeys
 */

export const state = () => ({
  loggedIn: false,
  currentUser: {},
  redirectURL: '',
  permissions: {
    timeOffPermissions: {},
    projectPermissions: {},
  },
  accessKeys: null,
})

export const actions = {
  async fetchCurrentUser({ commit }) {
    const result = await this.$axios({
      method: 'GET',
      url: '/api/session/getuser',
    })
    commit('setCurrentUser', result.data)
  },
  async fetchAccessKeys({ commit }) {
    const result = await this.$axios({
      method: 'GET',
      url: '/api/Permissions/AccessKeys',
    })
    commit('setAccessKeys', result.data)
  },
  async fetchPermissions({ commit }) {
    const result = await this.$axios({
      method: 'GET',
      url: '/api/permissions',
    })
    commit('setPermissions', result.data)
  },
  async logout({ commit }) {
    await this.$axios({
      method: 'POST',
      url: '/api/session/logout',
    })
      .then((result) => {
        commit('setAuthentication', false)
        commit('setCurrentUser', {})
        this.$router.push('/login')
        this.$cookies.remove('redirectURL')
      })
      .catch((error) => {
        this.$log.warn(error.response)
        throw error
      })
  },
}
export const mutations = {
  setCurrentUser: (state, payload) => {
    state.currentUser = payload
  },
  setAuthentication: (state, payload) => {
    state.loggedIn = payload
  },
  setRedirectURL: (state, payload) => {
    state.redirectURL = payload
  },
  setPermissions: (state, payload) => {
    state.permissions = payload
  },
  setAccessKeys: (state, payload) => {
    state.accessKeys = payload
  },
}
export const getters = {
  getCurrentUser(state) {
    return state.currentUser
  },
  getPermissions(state) {
    return state.permissions
  },
  getAccessKeys(state) {
    return state.accessKeys
  },
  getLoggedIn(state) {
    return state.loggedIn
  },
  getRedirectURL(state) {
    return state.redirectURL
  },
}

/**
 * @vuex-state {array} ownProjects - contains owned projects list
 */

export const state = () => ({
  ownProjects: [],
})

export const actions = {
  async fetchOwnProjects({ commit }, consultantId) {
    const result = await this.$axios({
      method: 'GET',
      url: `/api/Projects/OwnProjects`,
      params: (consultantId && { userId: consultantId }) || {},
    })
    commit('setOwnProjects', result.data)
  },
}
export const mutations = {
  setOwnProjects: (state, payload) => {
    state.ownProjects = payload
  },
}
export const getters = {
  getOwnProjects(state) {
    return state.ownProjects
  },
}

const statuses = [
  {
    key: 105,
    color: '#a1a1a1',
    name: 'Bid Stage',
  },
  {
    key: 140,
    color: '#a1a1a1',
    name: 'Proposed',
  },
  {
    key: 213,
    color: '#a4dd74',
    name: 'Pending',
  },
  {
    key: 135,
    color: '#a1a1a1',
    name: 'On Hold',
  },
  {
    key: 210,
    color: '#a4dd74',
    name: 'Okay to Start',
  },
  {
    key: 310,
    color: '#33ae10',
    name: 'In Progress',
  },
  {
    key: 610,
    color: '#448fea',
    name: 'Delivered',
  },
  {
    key: 605,
    color: '#448fea',
    name: 'Completed',
  },
  {
    key: 601,
    color: '#448fea',
    name: 'Cancelled',
  },
  {
    key: 505,
    color: '#e85342',
    name: 'Blocked',
  },
  {
    key: 300,
    color: '#33ae10',
    name: 'Active',
  },
  {
    key: 125,
    color: '#a1a1a1',
    name: 'Inactive',
  },
  {
    key: 110,
    name: 'Estimate',
  },
  {
    key: 130,
    color: '#a1a1a1',
    name: 'Not Started',
  },
  {
    key: 220,
    name: 'Scheduled',
  },
  {
    key: 540,
    name: 'Rejected',
  },
]

export { statuses }

export default {}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { getStatusColor } from '~/components/dashboard/tables/common/functions.js'

/**
 * @vue-prop {string} title - page title
 * @vue-prop {string} statusText - text line, on Project Detail page it will be project-validation of project
 * @vue-data {Array} tabs - list of tabs to be rendered
 * @vue-computed {String} statusColor - get color based on project status
 */

export default {
  name: 'DashboardHeader',
  props: {
    title: {
      type: String,
      default: 'Project Dashboard',
    },
    statusText: {
      type: String,
      default:
        'Check the latest validation status of projects and get an overview of their key control approvals.',
    },
    classes: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getProjectDetails: 'dashboard/requests/getProjectDetails',
    }),
    statusColor() {
      return getStatusColor(this.getProjectDetails.status)
    },
  },
}

import { render, staticRenderFns } from "./login.vue?vue&type=template&id=2de51a2d&scoped=true&"
import script from "./login.vue?vue&type=script&lang=js&"
export * from "./login.vue?vue&type=script&lang=js&"
import style0 from "./login.vue?vue&type=style&index=0&lang=css&"
import style1 from "./login.vue?vue&type=style&index=1&id=2de51a2d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.9.8_559ffc97fd41de05d12663d7fb949156/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2de51a2d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/usr/src/nuxt-app/components/global/Loading/Loading.vue').default,Header: require('/usr/src/nuxt-app/components/global/Header.vue').default})

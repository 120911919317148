//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Logo',
  props: {
    /**
     * Color of logo: white | black | blue
     */
    variant: {
      type: String,
      default: '',
    },
    /**
     * Width of logo
     */
    width: {
      type: String,
      default: '297px',
    },
    /**
     * Height of logo
     */
    height: {
      type: String,
      default: '60px',
    },
  },
  data() {
    return {
      variants: {
        white: '#FFFFFF',
        black: '#000000',
        blue: '#286EFE',
      },
    }
  },
  computed: {
    variantColor() {
      if (this.variant === '') {
        return this.variants.black
      } else if (this.variant === 'white') {
        return this.variants.white
      } else {
        return this.variants.blue
      }
    },
  },
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7d3a5a6a = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _b4d4bac6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2c11d034 = () => interopDefault(import('../pages/dashboard/my-projects.vue' /* webpackChunkName: "pages/dashboard/my-projects" */))
const _991933de = () => interopDefault(import('../pages/dashboard/projects.vue' /* webpackChunkName: "pages/dashboard/projects" */))
const _51086bc7 = () => interopDefault(import('../pages/error/access.vue' /* webpackChunkName: "pages/error/access" */))
const _0dd788a9 = () => interopDefault(import('../pages/error/credentials.vue' /* webpackChunkName: "pages/error/credentials" */))
const _5af7ec2b = () => interopDefault(import('../pages/timeoff/admin-requests.vue' /* webpackChunkName: "pages/timeoff/admin-requests" */))
const _41e98801 = () => interopDefault(import('../pages/timeoff/approved-requests.vue' /* webpackChunkName: "pages/timeoff/approved-requests" */))
const _6f4e2130 = () => interopDefault(import('../pages/timeoff/create/index.vue' /* webpackChunkName: "pages/timeoff/create/index" */))
const _6a8ebe6c = () => interopDefault(import('../pages/timeoff/my-requests.vue' /* webpackChunkName: "pages/timeoff/my-requests" */))
const _0ff86cca = () => interopDefault(import('../pages/timeoff/team-requests.vue' /* webpackChunkName: "pages/timeoff/team-requests" */))
const _58f7a671 = () => interopDefault(import('../pages/dashboard/project/overview/_id.vue' /* webpackChunkName: "pages/dashboard/project/overview/_id" */))
const _eac93f22 = () => interopDefault(import('../pages/dashboard/project/project-approval/_id.vue' /* webpackChunkName: "pages/dashboard/project/project-approval/_id" */))
const _2af01665 = () => interopDefault(import('../pages/dashboard/project/project-validation/_id.vue' /* webpackChunkName: "pages/dashboard/project/project-validation/_id" */))
const _6dd88ca2 = () => interopDefault(import('../pages/dashboard/project/task-validation/_id.vue' /* webpackChunkName: "pages/dashboard/project/task-validation/_id" */))
const _e6c809c4 = () => interopDefault(import('../pages/timeoff/edit/_id.vue' /* webpackChunkName: "pages/timeoff/edit/_id" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _7d3a5a6a,
    name: "dashboard"
  }, {
    path: "/login",
    component: _b4d4bac6,
    name: "login"
  }, {
    path: "/dashboard/my-projects",
    component: _2c11d034,
    name: "dashboard-my-projects"
  }, {
    path: "/dashboard/projects",
    component: _991933de,
    name: "dashboard-projects"
  }, {
    path: "/error/access",
    component: _51086bc7,
    name: "error-access"
  }, {
    path: "/error/credentials",
    component: _0dd788a9,
    name: "error-credentials"
  }, {
    path: "/timeoff/admin-requests",
    component: _5af7ec2b,
    name: "timeoff-admin-requests"
  }, {
    path: "/timeoff/approved-requests",
    component: _41e98801,
    name: "timeoff-approved-requests"
  }, {
    path: "/timeoff/create",
    component: _6f4e2130,
    name: "timeoff-create"
  }, {
    path: "/timeoff/my-requests",
    component: _6a8ebe6c,
    name: "timeoff-my-requests"
  }, {
    path: "/timeoff/team-requests",
    component: _0ff86cca,
    name: "timeoff-team-requests"
  }, {
    path: "/dashboard/project/overview/:id?",
    component: _58f7a671,
    name: "dashboard-project-overview-id"
  }, {
    path: "/dashboard/project/project-approval/:id?",
    component: _eac93f22,
    name: "dashboard-project-project-approval-id"
  }, {
    path: "/dashboard/project/project-validation/:id?",
    component: _2af01665,
    name: "dashboard-project-project-validation-id"
  }, {
    path: "/dashboard/project/task-validation/:id?",
    component: _6dd88ca2,
    name: "dashboard-project-task-validation-id"
  }, {
    path: "/timeoff/edit/:id?",
    component: _e6c809c4,
    name: "timeoff-edit-id"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

/**
 * @vuex-state {array} elements - Initial table value
 */

export const state = () => ({
  elements: [
    {
      id: '1',
      type: 'task',
      name: 'Name 1',
      descr:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, ea.',
      roles: [
        {
          name: 'BE',
          value: '60',
        },
        {
          name: 'FDS',
          value: '60',
        },
        {
          name: 'PM',
          value: '60',
        },
        {
          name: 'QA',
          value: '60',
        },
      ],
      hours: '20',
      cost: '20',
      fees: '20',
      elements: [
        {
          id: '2',
          type: 'task',
          name: 'Name 2',
          descr:
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, ea.2',
          roles: [
            {
              name: 'BE',
              value: '60',
            },
            {
              name: 'FDS',
              value: '60',
            },
            {
              name: 'PM',
              value: '60',
            },
            {
              name: 'QA',
              value: '60',
            },
          ],
          hours: '20',
          cost: '20',
          fees: '20',
          elements: [
            {
              id: '3',
              type: 'task',
              name: 'Name 33',
              descr:
                'Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, ea.',
              roles: [
                {
                  name: 'BE',
                  value: '60',
                },
                {
                  name: 'FDS',
                  value: '60',
                },
                {
                  name: 'PM',
                  value: '60',
                },
                {
                  name: 'QA',
                  value: '60',
                },
              ],
              hours: '20',
              cost: '20',
              fees: '20',
              elements: [],
            },
          ],
        },
      ],
    },
    {
      id: '4',
      type: 'task',
      name: 'Name 1',
      descr:
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, ea.',
      roles: [
        {
          name: 'BE',
          value: '60',
        },
        {
          name: 'FDS',
          value: '60',
        },
        {
          name: 'PM',
          value: '60',
        },
        {
          name: 'QA',
          value: '60',
        },
      ],
      hours: '20',
      cost: '20',
      fees: '20',
      elements: [
        {
          id: '5',
          type: 'task',
          name: 'Name 2',
          descr:
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, ea.2',
          roles: [
            {
              name: 'BE',
              value: '60',
            },
            {
              name: 'FDS',
              value: '60',
            },
            {
              name: 'PM',
              value: '60',
            },
            {
              name: 'QA',
              value: '60',
            },
          ],
          hours: '20',
          cost: '20',
          fees: '20',
          elements: [
            {
              id: '6',
              type: 'task',
              name: 'Name 33',
              descr:
                'Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, ea.',
              roles: [
                {
                  name: 'BE',
                  value: '60',
                },
                {
                  name: 'FDS',
                  value: '60',
                },
                {
                  name: 'PM',
                  value: '60',
                },
                {
                  name: 'QA',
                  value: '60',
                },
              ],
              hours: '20',
              cost: '20',
              fees: '20',
              elements: [],
            },
          ],
        },
      ],
    },
  ],
})
export const actions = {
  updateElements: ({ commit }, payload) => {
    commit('updateElements', payload)
  },
}
export const mutations = {
  updateElements: (state, payload) => {
    state.elements = payload
  },
}
export const getters = {
  loadedTasks(state) {
    return state.elements
  },
}

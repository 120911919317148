export default function ({ store, route, redirect, app, req, res, next }) {
  if (!store.state.global.auth.loggedIn) {
    const redirectPage = app.$cookies.get('redirectURL')
    return store
      .dispatch('global/auth/fetchPermissions')
      .then(() => store.dispatch('global/auth/fetchCurrentUser'))
      .then(() => store.dispatch('global/auth/fetchAccessKeys'))
      .then(
        (result) => {
          store.commit('global/auth/setAuthentication', true)

          if (redirectPage && redirectPage !== '/') {
            store.commit('global/loading/setLoading', true)
            window.$nuxt.$router.push(redirectPage)
            app.$cookies.remove('redirectURL')
          }
        },
        (error) => {
          if (
            !redirectPage &&
            route.fullPath !== '/' &&
            route.fullPath !== '/login'
          ) {
            app.$cookies.set('redirectURL', route.fullPath)
          }
          app.$appInsights.trackTrace({
            message: `User isn't authenticated: ${error.response.status}`,
          })
          redirect('/login')
        }
      )
      .catch((error) => {
        this.$log.warn(error.response)
        throw error
      })
  }
}

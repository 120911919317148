//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @vue-prop {Boolean} activeTrigger - Trigger for displaying spinner
 * @vue-prop {Boolean} isFull - Set up full screen mode
 */

export default {
  name: 'Loading',
  props: {
    /**
     * Trigger for displaying spinner
     */
    activeTrigger: {
      type: Boolean,
      default: false,
    },
    /**
     * Set up full screen mode
     */
    isFull: {
      type: Boolean,
      default: false,
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'FiltersInfo',
  props: {
    isProjectDashboard: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getAppliedFilters: 'global/filters/getAppliedFilters',
      getStartResultCount: 'global/filters/getStartResultCount',
      getAmountFilteredItems: 'global/filters/getAmountFilteredItems',
    }),
    appliedFilters() {
      return this.getAppliedFilters
    },
  },
  mounted() {
    this.setStartResultCount(0)
  },
  methods: {
    ...mapActions({
      deleteFilters: 'global/dynamicFilters/deleteFilters',
    }),
    ...mapMutations({
      setStartResultCount: 'global/filters/setStartResultCount',
    }),
  },
}

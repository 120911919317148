/**
 * @vuex-state {string} accountMembership - contains info about current manager and managees
 * @vuex-state {string} consultantMembership - contains info about  manager and managees for some consultant
 */

export const state = () => ({
  accountMembership: {
    managees: [],
  },
  consultantMembership: {},
})

export const actions = {
  async fetchAccountMembership({ commit }, consultantId) {
    const result = await this.$axios({
      method: 'GET',
      url: '/api/Users/AccountMembership',
      params: (consultantId && { userId: consultantId }) || {},
    })
    if (consultantId) {
      commit('setConsultantMembership', result.data)
    } else {
      commit('setAccountMembership', result.data)
    }
  },
}
export const mutations = {
  setAccountMembership: (state, payload) => {
    state.accountMembership = payload
  },
  setConsultantMembership: (state, payload) => {
    state.consultantMembership = payload
  },
}
export const getters = {
  getAccountMembership(state) {
    return state.accountMembership
  },
  getConsultantMembership: (state) => {
    return state.consultantMembership
  },
}

export const state = () => ({
  projectDetails: {},
  fetchedClients: {},
  fetchedLeaders: {},
  fetchedCircles: {},
  fetchedDepartments: {},
  isTaskError: null,
  projectApprovalPhases: [],
  approvalAndContainer: {},
  projectApproversList: [],
  approvalsPossibility: null,
})

export const mutations = {
  setProjectDetails(state, payload) {
    state.projectDetails = payload
  },
  setFetchedClients: (state, payload) => {
    state.fetchedClients = payload
  },
  setFetchedLeaders: (state, payload) => {
    state.fetchedLeaders = payload
  },
  setFetchedCircles: (state, payload) => {
    state.fetchedCircles = payload
  },
  setFetchedDepartments: (state, payload) => {
    state.fetchedDepartments = payload
  },
  resetProjectDetailsErrors: (state) => {
    state.projectDetails.syncValidationInfo = null
  },
  setProjectApprovalPhases(state, payload) {
    state.projectApprovalPhases = payload
  },
  setApprovalAndContainer(state, payload) {
    state.approvalAndContainer = payload
  },
  setProjectApproversList(state, payload) {
    state.projectApproversList = payload
  },
  setIsTaskError: (state, payload) => {
    state.isTaskError = payload
  },
  setApprovalsPossibility: (state, payload) => {
    state.approvalsPossibility = payload
  },
}

export const actions = {
  async fetchProjectDetails({ commit }, id) {
    const result = await this.$axios({
      method: 'GET',
      url: `/api/Projects/${id}`,
    })
    result.data.syncValidationInfo?.errors?.length > 0
      ? (result.data.isErrors = true)
      : (result.data.isErrors = false)
    commit('setProjectDetails', result.data)
  },
  async addYourselfToProject({ commit }, projectId) {
    const data = {
      projectId,
    }
    await this.$axios({
      method: 'POST',
      url: '/api/Projects/Invite',
      data,
    })
  },
  async fetchIsTaskError({ commit }, projectId) {
    await this.$axios({
      method: 'GET',
      url: `/api/Stories/HasAnyInvalid?projectId=${projectId}`,
    }).then((response) => {
      commit('setIsTaskError', response.data)
    })
  },
  async fetchClients({ commit, state }, query) {
    const result = await this.$axios({
      method: 'POST',
      url: '/api/Clients',
      data: {
        filter: {
          matching: query,
        },
      },
    })
    commit('setFetchedClients', {
      data: result.data.data,
      total: result.data.total,
    })
  },
  async fetchLeaders({ commit }, query) {
    const result = await this.$axios({
      method: 'POST',
      url: '/api/Users/ProjectLeads',
      data: {
        filter: {
          byFullName: query,
        },
      },
    })
    commit('setFetchedLeaders', {
      data: result.data.data,
      total: result.data.total,
    })
  },
  async fetchFilterValues({ commit }, query) {
    const result = await this.$axios({
      method: 'GET',
      url: '/api/Projects/FilterValues',
    })
    commit('setFetchedCircles', {
      data: result.data.circles,
    })
    commit('setFetchedDepartments', {
      data: result.data.organizations.departments,
    })
  },
  async fetchProjectApprovalPhases({ commit }, id) {
    const result = await this.$axios({
      method: 'GET',
      url: `/api/Projects/ApprovalPhases?projectId=${id}`,
    })
    result.data.forEach((el) => {
      return el.approvals.forEach((approval, i) => {
        if (approval.counter > 1) {
          approval.subApprovals.length > 1
            ? (approval.name = approval.name + ` (${approval.counter})`)
            : (approval.subApprovals[0].name = `${approval.subApprovals[0].name} (${approval.counter})`)
        }
      })
    })
    commit('setProjectApprovalPhases', result.data)
  },
  async fetchApprovalAndContainer({ commit }, { projectId, guid }) {
    const params = {
      projectId,
      guid,
    }

    const result = await this.$axios({
      method: 'GET',
      url: `/api/Projects/ApprovalAndContainer`,
      params,
    })
    if (result.data.container.counter > 1) {
      result.data.container.subApprovals.length > 1
        ? (result.data.container.name = `${result.data.container.name} (${result.data.container.counter})`)
        : (result.data.approval.name = `${result.data.approval.name} (${result.data.container.counter})`)
    }
    commit('setApprovalAndContainer', result.data)
  },
  async approveApproval({ commit }, { projectId, guid, comment, link }) {
    const data = {
      projectId,
      guid,
      comment,
      link,
    }
    await this.$axios({
      method: 'POST',
      url: '/api/Projects/Approvals/Approve',
      data,
    })
  },
  async unapproveApproval({ commit }, { projectId, guid, comment, link }) {
    const data = {
      projectId,
      guid,
      comment,
      link,
    }
    await this.$axios({
      method: 'POST',
      url: '/api/Projects/Approvals/Unapprove',
      data,
    })
  },
  async fetchProjectApproversList({ commit }, { id, key }) {
    const result = await this.$axios({
      method: 'GET',
      url: `/api/Projects/Approvals/Approvers?projectId=${id}&key=${key}`,
    })
    commit('setProjectApproversList', result.data)
  },
  async sendRequestApprovalRequest({ commit }, data) {
    await this.$axios({
      method: 'POST',
      url: '/api/Projects/Approvals/RequestReview',
      data,
    })
  },
  async fetchApprovalsPossibility({ commit }, id) {
    const result = await this.$axios({
      method: 'GET',
      url: `/api/Projects/Approvals/ArePossible?projectId=${id}`,
    })
    commit('setApprovalsPossibility', result.data)
  },
  async changeProjectStatus({ commit }, projectId) {
    const data = {
      projectId,
    }
    await this.$axios({
      method: 'POST',
      url: '/api/ProjectBlockActivity/Change',
      data,
    })
  },
}

export const getters = {
  getProjectDetails: (state) => {
    return state.projectDetails
  },
  getFetchedClients(state) {
    return state.fetchedClients
  },
  getFetchedLeaders(state) {
    return state.fetchedLeaders
  },
  getFetchedCircles(state) {
    return state.fetchedCircles
  },
  getFetchedDepartments(state) {
    return state.fetchedDepartments
  },
  getProjectApprovalPhases(state) {
    return state.projectApprovalPhases
  },
  getApprovalAndContainer(state) {
    return state.approvalAndContainer
  },
  getProjectApproversList(state) {
    return state.projectApproversList
  },
  getIsTaskError(state) {
    return state.isTaskError
  },
  getApprovalsPossibility: (state) => {
    return state.approvalsPossibility
  },
}

/**
 * @vuex-state {boolean} loading - global loader
 */

export const state = () => ({
  loading: false,
})

export const mutations = {
  setLoading: (state, payload) => {
    state.loading = payload
  },
}
export const getters = {
  getLoading(state) {
    return state.loading
  },
}

//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

/**
 * @vue-props {String} filterName - name of a filter
 * @vue-props {String} labelName - name of label
 * @vue-props {String} storeName - name of a dynamically created vuex store
 * @vue-props {Boolean} multiple - if true - multiple selection in filter, if false - single selection
 */

export default {
  name: 'FilterLabel',
  props: {
    filterName: {
      type: String,
      default: 'Filter name',
      required: true,
    },
    labelName: {
      type: String,
      default: 'Label name',
      required: true,
    },
    storeName: {
      type: String,
      default: '',
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getFilterVuex: 'global/dynamicFilters/getFilter',
    }),
    filterValues() {
      const defaultValue = this.multiple ? [] : null
      return this.getFilterVuex({
        name: this.storeName,
        defaultValue,
      })
    },
    filterValuesStr() {
      let valuesStr = 'Select'
      if (Array.isArray(this.filterValues)) {
        if (this.filterValues.length) {
          valuesStr = this.filterValues.reduce((str, obj, index) => {
            return `${str}${index > 0 ? ',' : ''} ${obj.name}`
          }, '')
        }
      } else if (
        typeof this.filterValues === 'object' &&
        this.filterValues !== null
      ) {
        valuesStr = this.filterValues.name
      }
      return valuesStr.length > 15 ? valuesStr.slice(0, 12) + '...' : valuesStr
    },
  },
}
